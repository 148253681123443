import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Privacypolicy.css';
import { OpenPopup } from '../../Actions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _Privacypolicy extends Component {
    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        openPopup: PropTypes.func,
        location: PropTypes.object,
        history: PropTypes.object
    };
    render() {
        const content = [
            {
                "control": "header", "value": "PRIVACY POLICY"
            },
            {
                "control": "p", "value": "Effective Date: July 7, 2020"
            },
            {
                "control": "p", "value": "Thank you for playing our games and using our services! This Privacy Policy describes:"
            },
            {
                "control": "list", "value": [
                    "The ways we collect personal data about you and why we do so",
                    "How we use your personal data, and",
                    "The choices you have about your personal data."]
            },
            {
                "control": "p", "value": "This Privacy Policy applies to BAPA technologies games, websites and related services, which we here collectively call the Service. We may periodically update this Privacy Policy by posting a new version on bapatechnologies.com. If we make any material changes, we will notify you as required under applicable law, including by posting a notice in the Service prior to the change becoming effective. Your continued use of the Service after the effective date will be subject to the new Privacy Policy."
            },
            {
                "control": "header1", "value": "THE DATA WE COLLECT"
            },
            {
                "control": "p", "value": "The categories of personal data we collect depend on the Services you use, and the requirements of applicable law."
            },
            {
                "control": "header3", "value": "Data you provide us directly."
            },
            {
                "control": "list", "value": [
                    "Contact information (such as name and email address)",
                    "Player name or Nickname",
                    "Profile information (such as profile photo)",
                    "Your messages to the Service (such as chat logs and player support tickets)"]
            },
            {
                "control": "header3", "value": "Data we collect automatically."
            },
            {
                "control": "list", "value": [
                    "Data about your account and game progress, including in most cases an automatically created internal account ID",
                    "Your IP address and mobile device identifiers (such as your device or advertising ID)",
                    "Data about your device, such as device name and operating system, browser type and language, internet service provider, and mobile carrier",
                    "Data we collect with cookies and similar technologies (see more below)",
                    "Approximate location data (as derived from IP address)",
                    "Data about your use of the Service, such as gameplay data and your interactions with other players inside the Service"
                ]
            },
            {
                "control": "header3", "value": "Data we collect from our partners."
            },
            {
                "control": "p", "value": "We also use third-party partners, such as social networking sites, data analytics providers and advertising networks to supplement information we have about you, such as:"
            },
            {
                "control": "list", "value": [
                    "Data we receive if you link a third party tool with the Service (such as Facebook or Google)",
                    "Demographic data (such as to determine the coarse location of your IP address)",
                    "Data to fight fraud (such as refund abuse in games or click fraud in advertising)",
                    "Data from platforms that the games run on (such as to verify payment)",
                    "Data for advertising and analytics purposes, so we can provide you a better Service",
                    "Data that you have made available to us from a third party service (such as Google or Facebook) via your privacy settings"
                ]
            },
            {
                "control": "header1", "value": "WHY DO WE COLLECT YOUR DATA"
            },
            {
                "control": "p", "value": "We use your data for a variety of business purposes, such as:"
            },
            {
                "control": "header3", "value": "To make the Service work."
            },
            {
                "control": "p", "value": "To perform the contract, we process data necessary to"
            },
            {
                "control": "list", "value": [
                    "Create accounts and allow you to play our games and use our Service",
                    "Operate the Service",
                    "Verify and confirm payments",
                    "Provide and deliver products and services you request",
                    "Send you Service-related communications"
                ]
            },
            {
                "control": "header3", "value": "To make the Service more suitable for our players."
            },
            {
                "control": "p", "value": "To provide a great Service to our players, we have a legitimate interest to collect and process necessary data to"
            },
            {
                "control": "list", "value": [
                    "Update and develop player profiles",
                    "Develop and improve the Service and player experience",
                    "Manage our relationship with you",
                    "Provide social features as part of the Service",
                    "Customize your Service experience",
                    "Respond to your comments and questions and provide player support",
                    "Send you related information, such as updates, security alerts, and support messages"
                ]
            },
            {
                "control": "header3", "value": "To show personalized advertisements."
            },
            {
                "control": "p", "value": "To show you personalized advertisements in the Service as well as in other websites and services (including email) we have a legitimate interest to process necessary data to"
            },
            {
                "control": "list", "value": [
                    "Track the content you access in connection with the Service and your online behavior",
                    "Deliver, target and improve our advertising and the Service"
                ]
            },
            {
                "control": "p", "value": "For information on how to opt-out from personalized advertisements, see section 'Your Rights and Options' below."
            },
            {
                "control": "header1", "value": "WHO CAN SEE YOUR DATA"
            },
            {
                "control": "p", "value": "Apart from BAPA, your data can be accessed by others in the following situations:"
            },
            {
                "control": "header3", "value": "Other players and users."
            },
            {
                "control": "p", "value": "Social features are a core component of our games. Other players and users may, for example, see your profile data, in-game activities and read the messages you have posted."
            },
            {
                "control": "header3", "value": "Other companies and public authorities."
            },
            {
                "control": "p", "value": "In order to combat fraud and illegal activity, we may process and disclose data with other companies and organizations and provide it to public authorities in response to lawful requests."
            },
            {
                "control": "p", "value": "We may also disclose your data based on your consent, to comply with the law or to protect the rights, property or safety of us, our players or others."
            },
            {
                "control": "header3", "value": "Advertising and Social Media partners."
            },
            {
                "control": "p", "value": "The Service includes features from our partners, such as social media interaction tools, functionalities through application programming interfaces (APIs) or software development kits (SDKs) and in-game advertising."
            },
            {
                "control": "p", "value": "A list of these partners is available below:"
            },
            {
                "control": "listWithLink", "value": [
                    {"name": "Facebook", "link" : "https://www.facebook.com/about/privacy"},
                    {"name":"Google", "link": "https://policies.google.com/privacy"}
                ]
            },
            {
                "control": "p", "value": "We encourage you to check their privacy policies to learn more about their data processing practices."
            },
            {
                "control": "p", "value": "These partners may access data regarding your activities and your device (such as your IP address, mobile identifiers, page(s) visited, location, time of day). We may also combine and share data we have collected about you with third-party advertising partners. These advertising partners may use this data (and similar data collected from other websites) for purposes of delivering targeted advertisements to you when you visit third-party services within their networks. These partners may operate under their own privacy policies. This practice is commonly referred to as “interest-based advertising” or “online behavioral advertising.” If you prefer not to share your personal data with third-party advertising partners, you may follow the instructions in “Your Rights and Options” below."
            },
            {
                "control": "header1", "value": "INTERNATIONAL DATA TRANSFERS"
            },
            {
                "control": "p", "value": "Our Service is global by nature and your data can therefore be transferred to anywhere in the world. Because different countries may have different data protection laws than your own country, we take steps to ensure adequate safeguards are in place to protect your data as explained in this Policy. Adequate safeguards that we may use include standard contractual clauses approved by EU Commission and other lawful safeguards."
            },
            {
                "control": "header1", "value": "YOUR RIGHTS AND OPTIONS"
            },
            {
                "control": "header3", "value": "Opt-out of marketing emails and other direct marketing."
            },
            {
                "control": "p", "value": "You may opt-out of receiving promotional communications, such as marketing emails from us by following the instructions in such communications, or by changing your in-game settings. The updated settings may not be effective immediately. Note that you may still continue to receive non-promotional emails from us, such as communications regarding the Service or updates to our Terms of Service or this Privacy Policy."
            },





            {
                "control": "header1", "value": "CONTACT US"
            },
            {
                "control": "p", "value": "If you have questions about data protection, or if you have any requests for resolving issues with your personal data, we encourage you to primarily contact us through the support features within each BAPA game, so we can reply to you more quickly. Alternatively, you may contact:"
            },
            {
                "control": "p", "value": "Name: Support Team, BAPA Technologies"
            },
            {
                "control": "p", "value": "Email: support@bapatechnologies.com"
            },
        ];
        return (
            <div>
                <div className="form_center">
                    <div className="center">
                        <div className="pp_container">
                            {
                                content.map((val,i)=>{
                                    if (val["control"] === "header"){
                                        return(
                                            <div key={i} className="header">{val["value"]}</div>
                                        );
                                    } else if (val["control"] === "header1"){
                                        return(
                                            <div key={i} className="header1">{val["value"]}</div>
                                        );
                                    } else if (val["control"] === "header3"){
                                        return(
                                            <div key={i} className="header3">{val["value"]}</div>
                                        );
                                    } else if (val["control"] === "p"){
                                        return(
                                            <div key={i} className="p">{val["value"]}</div>
                                        );
                                    } else if (val["control"] === "list"){
                                        return(
                                            <ul key={i}>
                                                {
                                                    val["value"].map((l,j)=>{
                                                        return(
                                                            <li key={i+ '-' + j} className="li">
                                                                {l}
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        );
                                    } else if (val["control"] === "listWithLink"){
                                        return(
                                            <ul key={i}>
                                                {
                                                    val["value"].map((l,j)=>{
                                                        return(
                                                            <li key={i+ '-' + j} className="li">
                                                                <div className="p">{l["name"]}</div>
                                                                <a className="a" target="_new" href={l["link"]}>{l["link"]}</a>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const Privacypolicy = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Privacypolicy)
);