import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './NumberSequence.css';
import { OpenPopup, SelectPlayer, ShowHeaderOptions } from '../../Actions';
import { Icon, Popup } from '../../Components';
import { GetPlayedGameData, StorePlayedGameData } from '../../Actions/gameActions';

var gameTitle = 'NUMB3RZ';
var gameName = 'BAPA_GAMES_' + gameTitle;

function mapStateToProps({ app, user, game }) {
    return {
        app: app,
        user: user,
        game: game
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p => {
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        getGameData: name => {
            dispatch(GetPlayedGameData(name));
        },
        storeGameData: (count, name) => {
            dispatch(StorePlayedGameData(count, name));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        }
    };
};
var timeinterval = null;
class _NumberSequence extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.gridSize = 4;
        this.color = ["none", " correct", " wrong", ""];
        this.myArr = this.createMarginArray(this.gridSize * this.gridSize, this.gridSize);
        this.state = {
            showIntro: this.getShowIntro(),
            size: 500,
            fontSize: 80,
            position: this.createArrayOfSize(this.gridSize * this.gridSize),
            color: this.createZeroArrayOfSize(this.gridSize * this.gridSize),
            showNumber: true,
            countdown: 5,
            isRotated: false,
            showToast: false,
            selectedNumber: "",
            completed: false
        }
    }
    getShowIntro(){
        return localStorage.getItem(gameName + "_SKIP_INTRO") === 'true' ? false : true;
    }
    SkipIntroAlways() {
        this.InitiateGame();
        localStorage.setItem(gameName + "_SKIP_INTRO", 'true');
    }
    componentDidMount() {
        this.props.getGameData(gameName);
        this.windowResize();
        window.addEventListener('load', () => this.windowResize());
        window.addEventListener('resize', () => this.windowResize());
        if (!this.getShowIntro()){
            this.InitiateGame();
        }
    }
    componentWillUnmount(){
        if (timeinterval !== null){
            clearInterval(timeinterval);
        }
    }
    windowResize() {
        if (window.innerWidth < 500) {
            this.setState({ size: window.innerWidth - 100, fontSize: parseInt(window.innerWidth / 8, 10) });
        } else {
            this.setState({ size: 500, fontSize: 80 });
        }
    }
    startGame() {
        this.InitiateGame();
    }
    InitiateGame() {
        var gridSize = this.gridSize;
        var position = this.createArrayOfSize(gridSize * gridSize);
        var possible = this.createPossiblePosition(gridSize * gridSize, gridSize);
        var color = this.createZeroArrayOfSize(gridSize * gridSize);
        var e = this.gridSize * this.gridSize - 1;
        for (var i = 0; i < 100; i++) {
            var r = this.generateRandomNumber(0, possible[e].length);
            var t = possible[e][r];
            if (t > -1 && t < gridSize * gridSize) {
                var c = position[t];
                position[t] = position[e];
                position[e] = c;
                e = t;
            }
        }
        this.setState({ completed: false, position: position, color: color, empty: e, showNumber: true, countdown: 5, showIntro: false });
        timeinterval = setInterval(() => {
            this.setState({ countdown: this.state.countdown - 1 });
            if (this.state.countdown < 0) {
                clearInterval(timeinterval);
                var cards = document.getElementsByClassName("flipper");
                for (var i = 0; i < cards.length; i++) {
                    var card = cards[i];
                    if (!card.classList.contains('on')) {
                        card.classList.remove('off');
                        card.classList.add('on');
                    } else {
                        card.classList.remove('on');
                        card.classList.add('off');
                    }
                }
                setTimeout(() => {
                    this.setState({ showNumber: false, isRotated: !this.state.isRotated });
                }, 300);
            }
        }, 1000);
    }
    createArrayOfSize(size) {
        var array = [];
        for (var i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }
    createZeroArrayOfSize(size) {
        var array = [];
        for (var i = 0; i < size; i++) {
            array.push(0);
        }
        return array;
    }
    createPossiblePosition(size, gsize) {
        var array = [];
        for (var i = 0; i < size; i++) {
            var a = [];
            if (i - gsize > -1 && i - gsize < size) {
                a.push(i - gsize);
            }
            if ((i + 1) % gsize !== 0) {
                a.push(i + 1);
            }
            if (i + gsize > -1 && i + gsize < size) {
                a.push(i + gsize);
            }
            if (i % gsize !== 0) {
                a.push(i - 1);
            }
            array.push(a);
        }
        return array;
    }
    createMarginArray(size, gsize) {
        var array = [];
        for (var i = 0; i < size; i++) {
            array.push([`-${parseInt(i / gsize, 10) * 100}%`, `-${i % gsize * 100}%`]);
        }
        return array;
    }
    generateRandomNumber = (min, max) => {
        var random = Math.random() * (max - min) + min;
        return parseInt(random, 10);
    }
    goHome() {
        if (this.props.user.loggedIn) {
            this.props.history.push(this.props.app.loginHome);
        } else {
            this.props.history.push(this.props.app.home);
        }
    }
    onClickCheck(x) {
        if (this.state.color[x] === 0 && !this.state.showNumber) {
            var color = this.state.color;
            if (this.state.selectedNumber === "") {
                this.RotateAnimationByChangingCss(x);
                var clickedNumber = parseInt((this.state.position[x] + 2) / 2, 10);
                color[x] = 3;
                this.setState({ color: color, selectedNumber: clickedNumber });
                this.checkGameOver(color);
            } else {
                var selectedNumber = parseInt((this.state.position[x] + 2) / 2, 10);
                if (selectedNumber === this.state.selectedNumber) {
                    this.RotateAnimationByChangingCss(x);
                    this.state.position.forEach(num => {
                        if (parseInt((this.state.position[num] + 2) / 2, 10) === selectedNumber) {
                            color[num] = 1;
                        }
                    });
                } else {
                    this.state.position.forEach(num => {
                        var nn = parseInt((this.state.position[num] + 2) / 2, 10);
                        if (nn === selectedNumber || nn === this.state.selectedNumber) {
                            if (color[num] === 0) {
                                this.RotateAnimationByChangingCss(num);
                            }
                            color[num] = 2;
                        }
                    });
                }
                this.setState({ color: color, selectedNumber: "" });
                this.checkGameOver(color);
            }
        }
    }
    checkGameOver(color) {
        var over = true;
        var count = 0;
        color.forEach(x => { if (x === 0) { over = false; } if (x === 1) { count++; } });
        if (over) {
            this.props.storeGameData(count / 2, gameName);
            setTimeout(() => {
                this.setState({ completed: true, lastgamecount: count });
                this.props.showHeaderOptions('leaderboard');
            }, 800);
        }
    }
    PlayAgain() {
        this.props.showHeaderOptions('');
        this.InitiateGame();
    }
    RotateAnimationByChangingCss(card) {
        var opencard = document.getElementById("card" + card);
        if (!opencard.classList.contains('on')) {
            opencard.classList.remove('off');
            opencard.classList.add('on');
        } else {
            opencard.classList.remove('on');
            opencard.classList.add('off');
        }
    }
    RowFr(s) {
        var fr = "";
        for (var i = 0; i < s; i++) {
            fr = fr + '1fr ';
        }
        return fr;
    }
    ShareText() {
        var right = "🟧";
        var wrong = "⬜️";
        var newLine = `
`;
        var result = gameTitle + newLine;
        this.state.color.forEach((x, i) => {
            if (i % 4 === 0) {
                result = result + newLine;
            }
            if (x === 1) {
                result = result + right;
            } else {
                result = result + wrong;
            }
        });
        result = result + newLine + "https://bapagames.com/single/" + gameTitle.toLowerCase()
        navigator.clipboard.writeText(result);
        this.setState({ showToast: true });
        setTimeout(() => {
            this.setState({ showToast: false });
        }, 3000);
    }
    render() {
        if (this.state.showIntro || this.props.app.showHeaderOption === "instruction") {
            return (
                <React.Fragment>
                    <div className='howtoplay'>
                        <div className='howtoplay_title'>
                            <h3>How to Play?</h3>
                            {
                                this.props.app.showHeaderOption === "instruction" &&
                                <div className='howtoplay_close' onClick={() => { this.props.showHeaderOptions('') }}>
                                    <Icon>close</Icon>
                                </div>
                            }
                        </div>
                        <div className='howtoplay_intro'>
                            <p>Memorize the <span className='howtoplay_bold'>NUMB3RZ</span> in five seconds.</p>
                            <p>A correct guess involves selecting the same numbers</p>
                            <p>An incorrect guess would open the pairs of incorrect numbers</p>
                        </div>
                        <div className='howtoplay_intro'>
                            <h3>Example:</h3>
                        </div>
                        <div className='howtoplay_intro'>
                            <div className='ns_game_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                <div className="game_number_cell correct" style={{ fontSize: `${this.state.fontSize}px` }}>1</div>
                                <div className="game_number_cell" style={{ fontSize: `${this.state.fontSize}px` }}></div>
                                <div className="game_number_cell correct" style={{ fontSize: `${this.state.fontSize}px` }}>1</div>
                                <div className="game_number_cell" style={{ fontSize: `${this.state.fontSize}px` }}></div>
                            </div>
                            <p style={{marginBottom: '10px'}}>The number <span className='howtoplay_bold'>1 is correctly guessed</span></p>
                        </div>
                        <div className='howtoplay_intro'>
                            <div className='ns_game_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                <div className="game_number_cell wrong" style={{ fontSize: `${this.state.fontSize}px` }}>1</div>
                                <div className="game_number_cell wrong" style={{ fontSize: `${this.state.fontSize}px` }}>3</div>
                                <div className="game_number_cell wrong" style={{ fontSize: `${this.state.fontSize}px` }}>1</div>
                                <div className="game_number_cell wrong" style={{ fontSize: `${this.state.fontSize}px` }}>3</div>
                            </div>
                            <p style={{marginBottom: '10px'}}>The number <span className='howtoplay_bold'>{'1 & 3 are incorrectly guessed'}</span></p>
                        </div>
                        {
                            this.props.app.showHeaderOption !== "instruction" &&
                            <div className='howtoplay_intro lineover'>
                                <div className='link_button btn_skip' onClick={() => { this.SkipIntroAlways() }}>
                                    {'Skip Always >'}
                                </div>
                                <div className='header_empty_middle'></div>
                                <div className='link_button btn_start' onClick={() => { this.startGame() }}>
                                    {'Start >'}
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            );
        } else {
            var result = this.props.game.numb3rz;
            var guess = [{ count: 0, persentage: '7%', name: 'count0' }, { count: 0, persentage: '7%', name: 'count2' }, { count: 0, persentage: '7%', name: 'count4' }, { count: 0, persentage: '7%', name: 'count6' }, { count: 0, persentage: '7%', name: 'count8' }];
            var average = 0;
            if (result !== null && result !== undefined) {
                result = JSON.parse(window.atob(this.props.game?.numb3rz));
                if (result.guess) {
                    var max = "count0";
                    Object.keys(result.guess).forEach(key => {
                        if (result.guess[key] > result.guess[max]) {
                            max = key;
                        }
                    });
                    guess.forEach((item, i) => {
                        guess[i].count = result.guess[guess[i].name];
                        if (item.count === result.guess[max] && item.count !== 0) {
                            guess[i].persentage = '100%';
                        } else if (item.count === 0) {
                            guess[i].persentage = '7%';
                        } else {
                            guess[i].persentage = ((result.guess[guess[i].name] * 100) / result.guess[max]) + '%';
                        }
                    });
                    guess.forEach((item, i) => {
                        if (i !== 0) {
                            average = average + (i * 2 * item.count);
                        }
                    });
                }
            } else {
                result = {
                    count : 0
                };
            }
            return (
                <React.Fragment>
                    <div className="ns_geme_main_container" style={{ minHeight: `${window.innerHeight - 60}px`}}>
                        <div className='toast_message' style={{ display: this.state.showToast ? 'block' : 'none' }}>Copied results to clipboard</div>
                        <div className="ns_game_main" style={{ width: `${this.state.size}px`, height: `${this.state.size}px`, gridTemplateRows: this.RowFr(this.gridSize) }}>
                            {
                                this.createArrayOfSize(this.gridSize).map((x, i) => {
                                    return (
                                        <div key={i} className='ns_game_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                            {
                                                this.createArrayOfSize(this.gridSize).map((y, j) => {
                                                    var z = ((x + 1) * this.gridSize) - this.gridSize + y;
                                                    return (
                                                        <div key={j} className='ns_game_number_section' onClick={() => this.onClickCheck(z)}>
                                                            <div id={"card" + z} className={`flipper on`}>
                                                                <div className='front'> {/* flipper off */}
                                                                    <div style={{ fontSize: `${this.state.fontSize}px` }} className={`game_number_cell${this.color[this.state.color[z]]}`}></div>
                                                                </div>
                                                                <div className='back'> {/* flipper on */}
                                                                    <div style={{ fontSize: `${this.state.fontSize}px` }} className={`game_number_cell${this.color[this.state.color[z]]}`}>{(this.state.showNumber || this.state.color[z] > 0) && parseInt((this.state.position[z] + 2) / 2, 10)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='message_over'>
                            {
                                this.state.countdown > -1 &&
                                <div className='message_over'>
                                    <div className='message_won_text'>
                                        {this.state.countdown === 0 ? "Start" : this.state.countdown}
                                    </div>
                                </div>
                            }
                            {
                                (this.state.completed && this.props.app.showHeaderOption !== "leaderboard") &&
                                <div className='ng_action_buttons'>
                                    <div className='ng_action_button' onClick={() => this.PlayAgain()}>
                                        Play Again
                                    </div>
                                    <div className='ng_action_button' onClick={() => this.ShareText()}>
                                        Share
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <Popup open={this.props.app.showHeaderOption === "leaderboard"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                        <div className='message_over'>
                            <div className='result_rows'>
                                <h3>STATISTICS</h3>
                            </div>
                            <div className='result_rows'>
                                <div className='result_flex'>
                                    <div className='stat_column'>
                                        <div className='stat_count'>{result.count ? result.count : "0"}</div>
                                        <div className='stat_name'>Played</div>
                                    </div>
                                    <div className='stat_column'>
                                        <div className='stat_count'>{((average / result.count || 0) + "").substring(0, 3)}</div>
                                        <div className='stat_name'>Average</div>
                                    </div>
                                </div>
                            </div>
                            <div className='result_rows'>
                                <h3>Guess Distribution</h3>
                            </div>
                            <div className='result_rows_guess'>
                                {
                                    guess.map((x, i) => {
                                        return (
                                            <div key={i} className='guess_count'>
                                                <div className='guess_count_number'>{x.name.substring(5)}</div>
                                                <div className='guess_progress_container'>
                                                    <div className='guess_progress' style={{ width: x.persentage, backgroundColor: x.name.substring(5) ===  ("" + (this.state.lastgamecount/2)) ? "#ff7126" : ""}}>
                                                        <div className='player_guess'>{x.count}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.completed && 
                                <div className='result_rows'>
                                    <div className='ng_action_buttons'>
                                        <div className='ng_action_button' onClick={() => this.PlayAgain()}>
                                            Play Again
                                        </div>
                                        <div className='ng_action_button' onClick={() => this.ShareText()}>
                                            Share
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Popup>
                </React.Fragment>
            );
        }
    }
}

export const NumberSequence = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_NumberSequence)
);