function prefix(action) {
  return `APP_${action}`;
}
  
export const AppActions = {
  SELECT_PLAYER: prefix('SELECT_PLAYER'),
  SELECT_GAME: prefix('SELECT_GAME'),
  HEADER_OPTION: prefix('HEADER_OPTION'),
  ERROR: prefix('ERROR'),
  LANG: prefix('LANG'),
  POPUP: prefix('POPUP'),
  RESET_ERR: prefix('RESET_ERR'),
  SHOW_PB: prefix('SHOW_PB'),
  HIDE_PB: prefix('HIDE_PB'),
  REG_FAIL: prefix('REG_FAIL'),
};

export const SelectPlayer = player => {
  return {
    type: AppActions.SELECT_PLAYER,
    payload: {
      player: player
    }
  };
};

export const SelectGame = game => {
  return {
    type: AppActions.SELECT_GAME,
    payload: {
      game: game
    }
  };
};

export const OpenPopup = name => {
  return {
    type: AppActions.POPUP,
    payload: name
  };
};

export const ShowHeaderOptions = option => dispatch => {
    dispatch({ type: AppActions.HEADER_OPTION, payload: option });
};