import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isActive] = useState(true);

  useEffect(() => {
    let interval = null;
    var date = new Date();
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(59 - date.getSeconds());
        setMinutes(59 - date.getUTCMinutes());
        setHours(23 - date.getUTCHours());
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="app">
      <div className="time">
      {("0"+ hours).slice(-2)}:{("0"+ minutes).slice(-2)}:{("0"+ seconds).slice(-2)}
      </div>
    </div>
  );
};

export default Timer;