import React, { Component } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import { PublicRoute } from './Route';
import { HomePage, NotFoundPage, GamePage, InsertWord, Privacypolicy, GameListPage, SupportPage, AccountDeletePage } from './Pages';

export default class App extends Component {
  render() {
    return (
      <Router history={createBrowserHistory()}>
        <Switch>
          <PublicRoute component={Privacypolicy} exact path="/privacypolicy" />
          <PublicRoute component={SupportPage} exact path="/support" />
          <PublicRoute component={AccountDeletePage} exact path="/deleteaccount" />
          <PublicRoute component={InsertWord} exact path="/insetdata/tamilee/game" />
          <PublicRoute component={HomePage} exact path="/" />
          <PublicRoute component={GameListPage} exact path="/:player" />
          <PublicRoute component={GamePage} exact path="/:player/:game" />
          <PublicRoute component={NotFoundPage} exact path="/not-found" />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}