import { GameActions } from '../Actions';
const TodaysData = "BAPA_GAMES_TODAY_DATA";
const ThirukkuralGameName = "BAPA_GAMES_THIRUKKURAL";
const initialState = {
  todayData: localStorage.getItem(TodaysData) || null,
  user: null,
  availableGames: {
    single: ["ARRANGE","TAMILEE","TAMILSOL","NUMB3RZ", "DAVE","THIRUKKURAL","CORRECTWORD"],
    two: [],
    multi: []
  },
  numb3rz: null,
  tamilee: null,
  thirukkural: localStorage.getItem(ThirukkuralGameName) || null
}

export const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case GameActions.UPD_TODAY_DATA: {
      localStorage.setItem(TodaysData, action.payload);
      return {
        ...state,
        todayData: action.payload
      };
    }
    case GameActions.USER: {
      return {
        ...state,
        user: action.payload
      };
    }
    case GameActions.GET_DATA: {
      return {
        ...state,
        numb3rz: action.payload
      };
    }
    case GameActions.GET_TAMILEE: {
      return {
        ...state,
        tamilee: action.payload
      };
    }
    case GameActions.THIRUKKURAL: {
      localStorage.setItem(ThirukkuralGameName, action.payload);
      return {
        ...state,
        thirukkural: action.payload
      };
    }
    case GameActions.GET_ARRANGE_LB: {
      localStorage.setItem(GameActions.GET_ARRANGE_LB, action.payload);
      return {
        ...state,
        arrangeLeaderboard: action.payload
      };
    }
    default:
      return state;
  }
};
