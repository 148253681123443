import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './CorrectWord.css';
import { OpenPopup, SelectPlayer, ShowHeaderOptions } from '../../Actions';
import { Icon, Popup } from '../../Components';
import Timer from '../../Components/BasicComponents/Timer';
import { decryptWithAES } from '../../Utils/CommonFunctions';
import words from '../../Asset/CorrectWord.json';

var timeinterval = null;
var gameTitle = 'CORRECTWORD';
var gameTitleInTamil = 'சரியான வார்த்தை';
var gameName = 'BAPA_GAMES_' + gameTitle;

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p => {
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        }
    };
};

class _CorrectWord extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.maxMistake = ['','','','',''];
        this.canvas = React.createRef();
        this.ctx = null;
        this.timer = 0;
        this.listOfWords = [];
        this.speed = 6;
        this.state = {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            wrongClick: 0,
            score: 0
        }
    }
    componentDidMount() {
        let rect = document.getElementById('canvas').getBoundingClientRect();
        const canvasEle = this.canvas.current;
        canvasEle.width = window.innerWidth;
        canvasEle.height = window.innerHeight-(rect.y+10);
        this.ctx = canvasEle.getContext("2d");
        this.InitiateGame();
        this.windowResize();
        window.addEventListener('load', ()=>this.windowResize());
        window.addEventListener('resize', ()=>this.windowResize());
        document.getElementById('canvas').addEventListener('click', (e)=>this.checkElementClicked(e), false);
        document.getElementById('canvas').oncontextmenu = (e)=>this.checkElementClicked(e);
        this.timerActionEverySecond();
    }
    timerActionEverySecond(){
        let rect = document.getElementById('canvas').getBoundingClientRect();
        timeinterval = setInterval(() => {
            this.timer++;
            this.ctx.clearRect(0,0,window.innerWidth, window.innerHeight - (rect.y));
            this.listOfWords.filter(x=> x.show)?.forEach(x=>{
                if (this.timer%this.speed === 0){
                    !this.listOfWords[x.id].clicked && this.listOfWords[x.id].y++;
                }
                this.writeText({ text: x.word, x: x.x, y: x.y, color: x.color, width: x.width, fontSize: x.fontSize });
                if (this.listOfWords[x.id].y > window.innerHeight - rect.y){
                    this.listOfWords[x.id].clicked = true;
                    // this.listOfWords[x.id + 1].show = true;
                    this.speed >= 4 && this.speed--;
                    if (5 - parseInt(x.id /10, 10) > 1){
                        this.speed = 5 - parseInt(x.id /10, 10);
                    }
                    setTimeout(() => {
                        if (this.listOfWords[x.id].show && this.listOfWords[x.id].answer){
                            this.setState({ wrongClick: this.state.wrongClick + 1 });
                        }
                        if (this.state.wrongClick === 5){
                            clearInterval(timeinterval);
                            this.gameOver();
                        }
                        this.listOfWords[x.id].show = false;
                    }, 50);
                }
            });
            this.listOfWords.filter(x=> !x.show && !x.clicked && x.showtime < this.timer)?.forEach(x=>{
                this.listOfWords[x.id].show = true;
            });
        }, 1);
    }
    checkElementClicked(event) {
        let rect = document.getElementById('canvas').getBoundingClientRect();
        var cx = event.pageX - rect.x,
            cy = event.pageY - rect.y;
        // Collision detection between clicked offset and element.
        this.listOfWords.filter(x=> x.show && !x.clicked)?.forEach(element=> {
            const { x, y, width } = element;
            if (cy > y-10 && cy < (y + 40) && cx > x && cx < (x + width + 20)) {
                this.listOfWords[element.id].clicked = true;
                this.listOfWords[element.id].color = this.listOfWords[element.id].answer ? 'green' : 'red';
                var proceed = false;
                if (this.listOfWords[element.id].color === "red"){
                    this.setState({ wrongClick: this.state.wrongClick + 1 });
                    if (this.state.wrongClick === this.maxMistake.length){
                        setTimeout(() => {
                            clearInterval(timeinterval);
                        }, 300);
                        setTimeout(() => {
                            this.gameOver();
                        }, 1000);
                    } else {
                        proceed = true;
                    }
                } else {
                    proceed = true;
                    this.setState({ score : this.state.score + 1 });
                }
                if (proceed) {
                    // this.listOfWords[element.id + 1].show = true;
                    setTimeout(() => {
                        this.listOfWords[element.id].show = false;
                    }, 1000);
                }
            }
        });
        return false;
    }
    writeText = (info, style = {}) => {
        const { text, x, y, color, fontSize } = info;
        const { fontFamily = 'Arial', textAlign = 'left', textBaseline = 'top' } = style;
        
        this.ctx.beginPath();
        this.ctx.font = fontSize + 'px ' + fontFamily;
        this.ctx.textAlign = textAlign;
        this.ctx.textBaseline = textBaseline;
        this.ctx.fillStyle = color;
        this.ctx.fillText(text, x, y);
        this.ctx.stroke();

        // this.ctx.beginPath();
        // this.ctx.font = fontSize + 'px ' + fontFamily;
        // this.ctx.textAlign = textAlign;
        // this.ctx.textBaseline = textBaseline;
        // this.ctx.fillStyle = color;
        // this.ctx.fillText(this.timer, window.innerWidth-50, 30);
        // this.ctx.stroke();

        // this.ctx.beginPath();
        // this.ctx.lineWidth = "2";
        // this.ctx.strokeStyle = "green";
        // this.ctx.rect(x-10, y-10, width+20, fontSize+10);
        // this.ctx.stroke();
    }
    gameOver(){
        var fontSize = 200;
        let rect = document.getElementById('canvas').getBoundingClientRect();
        this.ctx.clearRect(0,0,window.innerWidth, window.innerHeight - rect.y);
        var heightToShow = window.innerHeight*0.1;
        fontSize = this.WriteDynamicText({ 
            text: 'நன்றி', 
            x: window.innerWidth/2,
            y: heightToShow,
            color: "#000000",
            fontSize: fontSize
        });
        heightToShow = heightToShow + fontSize + (window.innerHeight/20);
        fontSize = this.WriteDynamicText({ 
            text: 'மதிப்பெண்: ' + this.state.score, 
            x: window.innerWidth/2,
            y: heightToShow,
            color: "#000000",
            fontSize: 50
        });
        heightToShow = heightToShow + 50;
        this.listOfWords.filter(x=>x.clicked)?.filter(x=>!x.answer)?.forEach(x=>{
            heightToShow = heightToShow + fontSize;
            this.WriteDynamicText({ 
                text: x.word + " -> " + x.correctWord, 
                x: window.innerWidth/2,
                y: heightToShow,
                color: "#000000",
                fontSize: 20
            });
        });
    }
    WriteDynamicText(style){
        const { text, x, y, color } = style;
        const { fontFamily = 'Arial', textAlign = 'center', textBaseline = 'top' } = style;
        var size = style.fontSize;
        for (var i = size; i > 5; i--){
            this.ctx.font = size + 'px ' + fontFamily;
            if (this.ctx.measureText(text).width < (window.innerWidth*0.9)){ break; }
            size--;
        }
        this.ctx.beginPath();
        this.ctx.font = size + 'px ' + fontFamily;
        this.ctx.textAlign = textAlign;
        this.ctx.textBaseline = textBaseline;
        this.ctx.fillStyle = color;
        this.ctx.fillText(text, x, y);
        this.ctx.stroke();
        return size;
    }
    windowResize(){
        this.setState({ innerWidth : window.innerWidth, innerHeight: window.innerHeight});
    }
    InitiateGame() {
        var l = Object.keys(words);
        var wordList = [];
        for (var i = 0; i < 100; i++){
            var rn = this.generateRandomNumber(1, l.length-1);
            var truefalse = this.generateRandomNumber(1, 10) < 7;
            var w = "";
            if (truefalse){
                w = l[rn-1];
            } else {
                w = words[l[rn-1]][0]
            }
            const fontSizeList = [20,25,28,35,40,50,30,45,55,65,70];
            var fontSize = fontSizeList[this.generateRandomNumber(0, fontSizeList.length-1)];
            this.ctx.font = fontSize+"px Arial";
            var width = this.ctx.measureText(w).width;
            var randomxpos = this.generateRandomNumber(20, window.innerWidth - (width + 20));
            wordList.push({ id: i, word: w, correctWord: l[rn-1], answer: truefalse, x: randomxpos, y: 0, width: width, show: i === 0, clicked: false, color: 'black', showtime: (i * 1000) - i * 50, fontSize: fontSize });
        }
        this.listOfWords = wordList;
    }
    generateRandomNumber = (min, max) => {
        var random = Math.random() * (max - min) + min;
        return parseInt(random, 10);
    }
    leaderboard() {
        var store = localStorage.getItem(gameName);
        var guess = [{ count: 0, persentage: '7%', name: 'count1' }, { count: 0, persentage: '7%', name: 'count2' }, { count: 0, persentage: '7%', name: 'count3' }, { count: 0, persentage: '7%', name: 'count4' }, { count: 0, persentage: '7%', name: 'count5' }, { count: 0, persentage: '7%', name: 'count6' }];
        var average = 0;
        if (store !== null && store !== undefined) {
            var result = decryptWithAES(store);
            if (result.guess) {
                var max = "count0";
                Object.keys(result.guess).forEach(key => {
                    if (result.guess[key] > result.guess[max]) {
                        max = key;
                    }
                });
                guess.forEach((item, i) => {
                    guess[i].count = result.guess[guess[i].name];
                    if (item.count === result.guess[max] && item.count !== 0) {
                        guess[i].persentage = '100%';
                    } else if (item.count === 0) {
                        guess[i].persentage = '7%';
                    } else {
                        guess[i].persentage = ((result.guess[guess[i].name] * 100) / result.guess[max]) + '%';
                    }
                });
                guess.forEach((item, i) => {
                    if (i !== 0) {
                        average = average + (i * item.count);
                    }
                });
            }
            result['guess'] = guess;
            return result;
        } else {
            return {
                count: 0,
                guess: guess
            };
        }
    }
    ShareText(data) {
        var newLine = `
`;
        var result = gameTitleInTamil + ' ' + data.day + newLine + newLine;
        result = result + newLine + "https://bapagames.com/single/correctword";
        navigator.clipboard.writeText(result);
        this.setState({ showToastShare: true });
        setTimeout(() => {
            this.setState({ showToastShare: false });
        }, 3000);
    }
    render() {
        var result = this.leaderboard();
        return (
            <React.Fragment>
                <div className='cw_show_wrong_clicks'>
                    {
                        this.maxMistake.map((x,i)=>{
                            return(
                                <div key={x+i} className={this.state.wrongClick >= (i+1) ? 'cw_wrong_click' : 'cw_wrong_click_not'}><Icon>wrong</Icon></div>
                            )
                        })
                    }
                </div>
                <div style={{ width: '100%' }}>
                    <canvas id={'canvas'} ref={this.canvas}></canvas>
                </div>
                <Popup open={this.props.app.showHeaderOption === "leaderboard"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='message_over'>
                        <div className='result_rows'>
                            <h3>புள்ளிவிவரங்கள்</h3>
                        </div>
                        <div className='result_rows'>
                            <div className='result_flex'>
                                <div className='stat_column'>
                                    <div className='stat_count'>{result.count ? result.count : "0"}</div>
                                    <div className='stat_name'>விளையாடியவை</div>
                                </div>
                            </div>
                        </div>
                        <div className='result_rows'>
                            <h3>புள்ளியியல் பட்டியல்</h3>
                        </div>
                        <div className='result_rows_guess'>
                            {
                                result.guess.map((x, i) => {
                                    var lasgamecount = 0;
                                    // if (data?.done) {
                                    //     data.array.forEach((x, row) => {
                                    //         if (x[0].letter !== "") {
                                    //             lasgamecount++;
                                    //         }
                                    //         if (x[0].letter !== "" && row === 5){
                                    //             x.forEach(l=>{
                                    //                 if (l.guess !== "correctplaceandword"){
                                    //                     lasgamecount = 0;
                                    //                 }
                                    //             })
                                    //         }
                                    //     })
                                    // }
                                    return (
                                        <div key={i} className='guess_count'>
                                            <div className='guess_count_number'>{x.name.substring(5)}</div>
                                            <div className='guess_progress_container'>
                                                <div className='guess_progress' style={{ width: x.persentage, backgroundColor: (i + 1) === lasgamecount ? "#ff7126" : "" }}>
                                                    <div className='player_guess'>{x.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            <div className='result_rows'>
                                <div className='ng_action_buttons'>
                                    <div className='ng_action_timer'>
                                        <span>{"அடுத்த சொல்:"}</span><br />
                                        <div id="timer" style={{ fontSize: '24px' }}>
                                            <Timer></Timer>
                                        </div>
                                    </div>
                                    <div className='ng_action_button' onClick={() => this.ShareText()}>
                                        {"பகிர்"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
                <Popup open={this.props.app.showHeaderOption === "instruction"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='howtoplay'>
                        <div className='howtoplay_title'>
                            <h3>எப்படி விளையாடுவது?</h3>
                        </div>
                        <div className='howtoplay_intro'>
                            <p>ஆறு முயற்சிகளில் <span className='howtoplay_bold'>{gameTitleInTamil}</span> ஐ யூகிக்கவும். ஒவ்வொரு நாளும் ஒரு புதிய <span className='howtoplay_bold'>{gameTitleInTamil}</span> கிடைக்கும்!</p>
                            <p>ஒவ்வொரு யூகத்திற்கும் ஐந்தெழுத்து இருக்க வேண்டும். சமர்ப்பிக்க சரிபார் பொத்தானை அழுத்தவும்.</p>
                            <p>ஒவ்வொரு யூகத்திற்கு பிறகும், உங்கள் யூகம் வார்த்தைக்கு எவ்வளவு நெருக்கமாக இருந்தது என்பதைக் கட்டத்தின் நிறம் காட்டும்.</p>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }
}

export const CorrectWord = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_CorrectWord)
);