import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Header.css';
import { SelectGame, SelectPlayer, ShowHeaderOptions } from '../../Actions';
import logo from '../../Images/logo.svg';
import { Icon } from '../BasicComponents/Icon';

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p =>{
            dispatch(SelectPlayer(p));
        },
        selectGame: g =>{
            dispatch(SelectGame(g));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        }
    };
};

class _Header extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            menuOpen: false
        }
    }
    componentDidMount(){
        var timer;
        this.nv.addEventListener("mousedown", ()=>{
            this.longpress = true;
            timer = setTimeout(()=>{
                if (this.longpress){
                    this.props.showHeaderOptions('setting_clear');
                }
            },4*1000);
        });
        this.nv.addEventListener("touchstart", ()=>{
            this.longpress = true;
            timer = setTimeout(()=>{
                if (this.longpress){
                    this.props.showHeaderOptions('setting_clear');
                }
            },4*1000);
        });
        this.nv.addEventListener("mouseup", ()=>{ this.longpress = false; clearTimeout(timer); });
        this.nv.addEventListener("mouseleave", ()=>{ this.longpress = false; clearTimeout(timer); });
        this.nv.addEventListener("touchend", ()=>{ this.longpress = false; clearTimeout(timer); });
        this.nv.addEventListener("touchcancel", ()=>{ this.longpress = false; clearTimeout(timer); });
    }
    render() {
        return (
            <React.Fragment>
                <div className="header_container">
                    <div className='header_logo' onClick={()=>{this.props.selectPlayer("");this.props.history.push('/')}}>
                        <img alt='BAPA' src={logo} className="header_logo_img"></img>
                    </div>
                    {/* <div className='app_logo' onClick={()=>{this.props.selectPlayer("");this.props.history.push('/')}}>
                        <Icon>app</Icon>
                    </div> */}
                    <div className='header_empty_middle'>
                    </div>
                    {
                        this.props.game?.availableGames[this.props.match.params.player]?.includes(this.props.match.params.game?.toUpperCase()) &&
                        <React.Fragment>
                            {/* {
                                this.props.match.params.game?.toUpperCase() === 'TAMILSOL' &&
                                <div className='header_icon' onClick={()=>{this.props.showHeaderOptions('idea')}}>
                                    <Icon>idea</Icon>
                                </div>
                            } */}
                            <div className='header_icon' onClick={()=>{this.props.showHeaderOptions('instruction')}}>
                                <Icon>instruction</Icon>
                            </div>
                            <div className='header_icon' onClick={()=>{this.props.showHeaderOptions('leaderboard')}}>
                                <Icon>cup</Icon>
                            </div>
                        </React.Fragment>
                    }
                    <div id="settings" ref={elem => this.nv = elem}  className='header_icon settings_icon' onClick={()=>{this.props.showHeaderOptions('settings')}}>
                        <svg viewBox="0 0 24 24">
                            <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"></path>
                        </svg>
                    </div>
                </div>
                {
                    <div>
                        Menu
                    </div>
                }
            </React.Fragment>
        );
    }
}

export const Header = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Header)
);