import { AppActions } from './appActions';
import moment from 'moment';
import { initializeApp, getApp } from 'firebase/app';
import { getAuth, setPersistence, signInAnonymously, inMemoryPersistence } from 'firebase/auth';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { decryptWithAES, encryptWithAES } from '../Utils/CommonFunctions';

const TodaysData = "BAPA_GAMES_TODAY_DATA";
const VERSION = "BAPA_GAMES_VERSION";
const firebaseConfig = {
    apiKey: "AIzaSyCWLGgrkpP0kNIvyyuf9EQyIbiOTRMcZzk",
    authDomain: "bapa-games.firebaseapp.com",
    databaseURL: "https://bapa-games.firebaseio.com",
    projectId: "bapa-games",
    storageBucket: "bapa-games.appspot.com",
    messagingSenderId: "572744768093",
    appId: "1:572744768093:web:88215f6f6b11f55d774bb4",
    measurementId: "G-L8Q1XP3P3J"
};
initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development"){
    const functions = getFunctions(getApp());
    // connectFunctionsEmulator(functions, "localhost", 5001);
}

export const LoadUserDetails = () => dispatch => {
    login(dispatch);
};

async function login(dispatch){
    const auth = getAuth(getApp());
    try {
        await setPersistence(auth, inMemoryPersistence);
        try {
            var user = await signInAnonymously(auth);
            dispatch({ type: GameActions.USER, payload: user.user });
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Error signInAnonymously", errorCode, errorMessage);
        }
    } catch (error_1) {
        // Handle Errors here.
        const errorCode_1 = error_1.code;
        const errorMessage_1 = error_1.message;
        console.log("Error setPersistence", errorCode_1, errorMessage_1);
    }
}
function prefix(action) {
    return `GAME_${action}`;
}
export const GameActions = {
    USER: prefix('USER'),
    UPD_TODAY_DATA: prefix('UPD_TODAY_DATA'),
    GET_DATA: prefix('GET_DATA'),
    GET_TAMILEE: prefix('GET_TAMILEE'),
    THIRUKKURAL: prefix('THIRUKKURAL'),
    GET_ARRANGE_LB: prefix('GET_ARRANGE_LB'),
};

export const GetTodayData = (ver, progress) => dispatch => {
    if (ver !== localStorage.getItem(VERSION)){
        if (localStorage.getItem(VERSION) == null){
            localStorage.setItem(VERSION, ver);
            var olddata = localStorage.getItem("BAPA_GAMES_TAMILEE");
            if (olddata !== null){
                var olddataObj = JSON.parse(atob(olddata));
                localStorage.setItem("BAPA_GAMES_TAMILSOL", encryptWithAES(olddataObj));
                localStorage.removeItem("BAPA_GAMES_TAMILEE");
            }
        }
    }
    var getWord = false;
    var decryptText = localStorage.getItem(TodaysData);
    if (decryptText) {
        var data = decryptWithAES(decryptText);
        if (data?.Date !== moment().utc().format("MM-DD")) {
            getWord = true;
        }
    } else {
        getWord = true;
    }
    if (getWord) {
        login(dispatch).then(()=>{
            const functions = getFunctions(getApp());
            var getData = httpsCallable(functions, 'getData');
            getData().then(data => {
                dispatch({ type: GameActions.UPD_TODAY_DATA, payload: data.data });
                localStorage.setItem(TodaysData, data.data);
            }).catch(error => {
                console.log(error);
            });
        });
    }
};

export const StorePlayedGameData = (count, gameName) => dispatch => {
    var existingData = localStorage.getItem(gameName);
    if (existingData === null) {
        var data = { count: 1, guess: { count0: 0, count2: 0, count4: 0, count6: 0, count8: 0 } };
        data.guess["count" + count] = data.guess["count" + count] + 1;
        var data_base64 = window.btoa(JSON.stringify(data));
        localStorage.setItem(gameName, data_base64);
        dispatch({ type: GameActions.GET_DATA, payload: data_base64 });
    } else {
        var result = JSON.parse(window.atob(existingData));
        result.count = result.count + 1;
        result.guess["count" + count] = result.guess["count" + count] + 1;
        var result_base64 = window.btoa(JSON.stringify(result));
        localStorage.setItem(gameName, result_base64);
        dispatch({ type: GameActions.GET_DATA, payload: result_base64 });
    }
};

export const GetPlayedGameData = gameName => dispatch => {
    var existingData = localStorage.getItem(gameName);
    dispatch({ type: GameActions.GET_DATA, payload: existingData });
};

export const GetTodayWordLocally = (data) => dispatch => {
    if (!data) {
        dispatch({ type: GameActions.GET_TAMILEE, payload: localStorage.getItem(GameActions.GET_TAMILEE) });
    } else {
        dispatch(GetTodayData(true));
    }
}

export const SilentUpdateTamilee = (data) => dispatch => {
    const functions = getFunctions(getApp());
    var tApiUpdate = httpsCallable(functions, 'tApiUpdate');
    tApiUpdate(data).catch(error => {
        console.log(error);
    });
}

export const InsetWordToFireBaseDataBase = (data) => dispatch => {
    const functions = getFunctions(getApp());
    var insertData = httpsCallable(functions, 'insertData');
    insertData(data).then(data => {
        console.log(data);
    }).catch(error => {
        console.log(error);
    });
}

export const GetArrangeLeaderBoard = (progress) => dispatch => {
    progress && dispatch({ type: AppActions.SHOW_PB });
    const functions = getFunctions(getApp());
    var leaderboard = httpsCallable(functions, 'arrlb');
    leaderboard().then(data => {
        dispatch({ type: GameActions.GET_ARRANGE_LB, payload: data.data });
        localStorage.setItem(GameActions.GET_TAMILEE, data.data);
        dispatch({ type: AppActions.HIDE_PB });
    }).catch(error => {
        console.log(error);
        dispatch({ type: AppActions.HIDE_PB });
    });
}

export const UpdateArrangeLeaderBoard = (progress, data) => dispatch => {
    progress && dispatch({ type: AppActions.SHOW_PB });
    const functions = getFunctions(getApp());
    var leaderboard = httpsCallable(functions, 'uparrlb');
    leaderboard(data).then(data => {
        dispatch({ type: GameActions.GET_ARRANGE_LB, payload: data.data });
        localStorage.setItem(GameActions.GET_TAMILEE, data.data);
        dispatch({ type: AppActions.HIDE_PB });
    }).catch(error => {
        console.log(error);
        dispatch({ type: AppActions.HIDE_PB });
    });
}

export const StoreThirukkuralGameData = (result, place,  gameName) => dispatch => {
    var existingData = localStorage.getItem(gameName);
    if (existingData === null) {
        var count = result ? 1 : 0;
        var data = { trial: 1, date: moment().format("YYYY-MM-DD"), count: 1, place: place, guess: { count1: 0, count2: 0, count3: 0, count4: 0, count5: 0 } };
        if (result){
            data.guess["count" + count] = 1;
        }
        dispatch({ type: GameActions.THIRUKKURAL, payload: encryptWithAES(data) });
    } else {
        var result_json = decryptWithAES(existingData);
        var upd = 0;
        if (result){
            if (result_json.date === moment().format("YYYY-MM-DD")){
                result_json.trial = result_json.trial + 1;
            } else {
                result_json.trial = 1;
                result_json.date = moment().format("YYYY-MM-DD");
                result_json.playCount = result_json.playCount + 1;
            }
            upd = result_json.trial;
            if (result_json.trial >= 5){
                upd = 5;
            }
            result_json.guess["count" + upd] = result_json.guess["count" + upd] + 1;
        } else {
            if (result_json.date === moment().format("YYYY-MM-DD")){
                result_json.trial = result_json.trial + 1;
            } else {
                result_json.trial = 1;
                result_json.playCount = result_json.playCount + 1;
                result_json.date = moment().format("YYYY-MM-DD");
            }
        }
        result_json.place = place
        dispatch({ type: GameActions.THIRUKKURAL, payload: encryptWithAES(result_json) });
    }
};
