import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './GameListPage.css';
import { Icon } from '../../Components';
import { getApp } from 'firebase/app';
import { collection, getDocs, getDoc, getFirestore, setDoc, doc } from "firebase/firestore";
import wordsList from '../../Asset/ExcelWordList.json';
import firebasewordsList from '../../Asset/FirebaseWordList.json';
import moment from 'moment';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _GameListPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    componentDidMount(){
        if (!["SINGLE","TWO","MULTI"].includes(this.props.match.params.player.toUpperCase())){
            this.props.history.push("/");
        }
        // this.prepareWordList();
    }
    async prepareWordList(){
        var list = wordsList;
        // console.log(list);
        const unique = (value, index, self) => {
            if (self.indexOf(value) !== index){
                // console.log(value);
            }
            return self.indexOf(value) === index
        }
        const uniquelist = list.filter(unique);
        console.log(uniquelist);


        var firelist = [];
        var firekural = [];
        firebasewordsList.forEach(x=> {firelist.push(x.data.Word); firekural.push(x.data.Kural);});
        // console.log(firelist);
        const uniquefirelist = firelist.filter(unique);
        const uniquefirekural = firekural.filter(unique);
        // console.log(uniquefirelist);

        for (var i = 0 ; i < uniquelist.length-1; i++){
            var random = this.generateRandomNumber(0, uniquelist.length-1);
            if (this.splitText(uniquelist[i]).split('|').length !== 5){
                console.log("Length < 5: ", this.splitText(uniquelist[i]).split('|').length ,uniquelist[i]);
            }
            var dummy = uniquelist[i];
            uniquelist[i] = uniquelist[random];
            uniquelist[random] = dummy;
        }
        var date = moment("2022-07-19");
        console.log(date);
        console.log(uniquelist);
        var newListToUpdateFirebase = [];
        for (var j = 0 ; j < uniquelist.length-1; j++){
            var kural = 1330;
            for (var k = 0;k<20;k++){
                var randomKural = this.generateRandomNumber(1, 1330);
                if (!uniquefirekural.includes(randomKural)){
                    kural = randomKural;
                    break;
                }
            }
            if (uniquefirelist.includes(uniquelist[j])){
                // console.log("TextAlreadyExist:", uniquelist[j]);
            } else {
                newListToUpdateFirebase.push({
                    id: "Date-" + date.format("MM-DD"),
                    data: {
                        Word: uniquelist[j],
                        Clue1 : "",
                        Clue2 : "",
                        Kural : kural
                    }
                });
                date.add(1, 'days');
            }
        }
        console.log(newListToUpdateFirebase);
        newListToUpdateFirebase.forEach(async x=>{
            const docRef = doc(getFirestore(getApp()), "TamilWordGame", x.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                await setDoc(doc(getFirestore(getApp()), "TamilWordGame", x.id), x.data);
            }
        })
    }
    generateRandomNumber = (min, max) => {
        var random = Math.random() * (max - min) + min;
        return parseInt(random, 10);
    }

    splitText(text) {
        var newText = "";
        var half = false;
        text.split("").reverse().forEach((x, i) => {
            if (["்", "ா", "ி", "ீ", "ு", "ூ", "ெ", "ே", "ை", "ொ", "ோ", "ௌ"].includes(x)) {
                half = true;
                newText = x + newText;
            } else if (half) {
                newText = "|" + x + newText;
                half = false;
            } else {
                newText = "|" + x + newText;
            }
        });
        return newText.substring(1);
    }

    async getListFromFirebase(){
        const querySnapshot = await getDocs(collection(getFirestore(getApp()), "TamilWordGame"));
        var json = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            json.push({ id: doc.id, data: doc.data() });
        });
        console.log(json);
    }
    GoToGame(name){
        this.props.history.push('/'+ this.props.match.params.player.toLowerCase() + '/' + name.toLowerCase());
    }
    render() {
        var gameList = {
            single: [
                {name: "CorrectWord", altName: "சரியான வார்த்தை", logo: <Icon>correctword</Icon>, description: 'கொடுக்கப்பட்ட ஏழு வார்த்தைகளை சரியாக வரிசைப்படுத்தி குறளை கண்டுபிடிக்க வேண்டும்.', host: 'local'},
                {name: "Thirukkural", altName: "திருக்குறள்", logo: <Icon>thirkkural</Icon>, description: 'கொடுக்கப்பட்ட ஏழு வார்த்தைகளை சரியாக வரிசைப்படுத்தி குறளை கண்டுபிடிக்க வேண்டும்.', host: 'prod'},
                {name: "TAMILSOL", altName: 'தமிழ்ச் சொல்', logo: <Icon>tamilee</Icon>, description: 'இது ஒரு தினசரி வார்த்தை விளையாட்டு. எளிய வார்த்தை விளையாட்டு, ஒரு நாளைக்கு ஒரு முறை மட்டுமே விளையாட முடியும்.', host: 'prod'},
                {name: "NUMB3RZ", logo: <Icon>numb3rz</Icon>, description: 'Memorize the NUMB3RZ in five seconds and click the pair of same numbers.', host: 'prod'},
                {name: "ARRANGE", logo: <Icon>arrange</Icon>, description: 'Arrange is a classic slide puzzle game. Reassemble a picture, by touching the block you want to move.', host: 'local'},
                {name: "DAVE", logo: <Icon>arrange</Icon>, description: 'Arrange is a classic slide puzzle game. Reassemble a picture, by touching the block you want to move.', host: 'local'},
            ],
            two: [
            ],
            multi: [
            ],
        };
        return (
            <div className="gl_containter">
                <div className='gl_intro'>
                    <p>
                        {'புதிய விளையாட்டு "திருக்குறள்"'}<br/>
                        {'விளையாடி மகிழுங்கள்'}
                    </p>
                    <div className='gl_intro_icon' onClick={()=>this.GoToGame("thirukkural")}><Icon>thirkkural</Icon></div>
                </div>
                <div>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2876950513590360"
                    crossOrigin="anonymous"></script>
                    {/* <!-- HomeScreen --> */}
                    <ins className="adsbygoogle"
                        style={{"display":"block"}}
                        data-ad-client="ca-pub-2876950513590360"
                        data-ad-slot="7275358041"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
                <div className='gl_grid'>
                    {
                        gameList[this.props.match.params.player.toLowerCase()].filter(x => {return x.host === 'prod' || (x.host === 'local' && window.location.hostname === "localhost")}).map((x,i)=>{
                            return(
                                <div key={i} className='gl_game_item' onClick={()=>this.GoToGame(x.name )}>
                                    <div className='gl_game_logo_content'>
                                        <div className='gl_logo'>
                                            <div>{x.logo}</div>
                                        </div>
                                        <div className='gl_game_title'>
                                            <div>{x.altName || x.name}</div>
                                        </div>
                                    </div>
                                    <div className='gl_game_description_content'>
                                        <div className='gl_game_description'>
                                            <p>{x.description}</p>
                                        </div>
                                        <div className='gl_game_play_btn'>
                                            <div>Play</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    gameList[this.props.match.params.player.toLowerCase()].length === 0 && 
                    <div>
                        No {this.props.match.params.player.toLowerCase()} player game available.
                    </div>
                }
                <div className='footer'>
                    <div className='copyright'>
                        © BAPA Technologies 2022
                    </div>
                </div>
            </div>
        );
    }
}

export const GameListPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_GameListPage)
);