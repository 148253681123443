import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Dave.css';
import { GetArrangeLeaderBoard, OpenPopup, SelectPlayer, ShowHeaderOptions, UpdateArrangeLeaderBoard } from '../../Actions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p =>{
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        },
        getLeaderboard: data => {
            dispatch(GetArrangeLeaderBoard(data));
        },
        updateLeaderboard: (progress, data) => {
            dispatch(UpdateArrangeLeaderBoard(progress, data));
        }
    };
};

class _Dave extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            userName: ""
        }
    }
    render() {
        return (
            <div>
            </div>
        );
    }
}

export const Dave = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Dave)
);