import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './NotFoundPage.css';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

class _NotFoundPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    goHome(){
        if (this.props.user.loggedIn){
            this.props.history.push(this.props.app.loginHome);
        } else {
            this.props.history.push(this.props.app.home);
        }
    }
    render() {
        return (
            <div className="container">
                <div className="nf_container">
                    <div className="form_center">
                        <div className="center margin30">
                            <div className="nf_404">404</div>
                            <div className="center margin30">Oops! Page not found</div>
                            <div className="center margin30">Sorry, but the page you are looking for is not found. Please make sure you have typed the correct URL.</div>
                            <div className="center pointer margin30" onClick={()=>this.goHome()}>Go to Home</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const NotFoundPage = withRouter(
    connect(
        mapStateToProps,
        null
    )(_NotFoundPage)
);