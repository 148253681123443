import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ArrangeImage } from '../ArrangeImage/ArrangeImage';
import { FindTheWord } from '../FindTheWord/FindTheWord';
import { NumberSequence } from '../NumberSequence/NumberSequence';
import { Dave } from '../Dave/Dave';
import { Thirukkural } from '../Thirukkural/Thirukkural';
import { CorrectWord } from '../CorrectWord/CorrectWord';

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _GamePage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    componentDidMount(){
        if (this.props.match.params.game.toUpperCase() === "TAMILEE"){
            this.props.history.push("/single/tamilsol")
        }
        if (!this.props.game.availableGames[this.props.match.params.player].includes(this.props.match.params.game.toUpperCase())){
            this.props.history.push("/");
        }
    }
    render() {
        var gameList = {
            CORRECTWORD: <CorrectWord/>,
            ARRANGE : <ArrangeImage/>,
            TAMILEE: <></>,
            TAMILSOL: <FindTheWord/>,
            NUMB3RZ: <NumberSequence/>,
            DAVE: <Dave/>,
            THIRUKKURAL: <Thirukkural/>
        };
        return(
            <React.Fragment>
                {
                    gameList[this.props.match.params.game.toUpperCase()]
                }
            </React.Fragment>
        )
    }
}

export const GamePage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_GamePage)
);