import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loading from '../Images/loading.svg';
import classNames from 'classnames';
import './Route.css';
import { Header } from '../Components/Header/Header';
import { Popup } from '../Components';
import { GetTodayData, LoadUserDetails, ShowHeaderOptions } from '../Actions';
import { PrivacyNotice } from '../Pages';

function mapStateToProps({ app, game }) {
  return { app, game };
}

const mapDispatchToProps = dispatch => {
  return {
    showHeaderOptions: option => {
      dispatch(ShowHeaderOptions(option));
    },
    gettodaydata: (version, progress) => {
        dispatch(GetTodayData(version, progress));
    },
    loadUser: () => {
      dispatch(LoadUserDetails());
    }
  };
};

class _PublicRoute extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    path: PropTypes.string,
    app: PropTypes.object,
    user: PropTypes.object,
    openPopup: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };
  constructor(props) {
      super(props);
      this.state = {
        privacyShow: false,
      }
  }
  componentDidMount(){
    this.props.loadUser();
    this.props.gettodaydata(this.props.app.appVersion, false);
  }
  clearDataInThisBrowser() {
    localStorage.clear();
    this.props.showHeaderOptions('');
    this.props.history.push("/");
  }
  feadback(){
    window.location.href = "mailto:support@bapatechnologies.com?subject=Feedback on BAPAGames.com.&body=%0A%0A%0A%0A%0A--------------------------------%0A--Please don't remove below content--%0ADevice summary:%0AUser ID: " + this.props.game?.user?.uid;
  }
  email(){
    window.location.href = "mailto:support@bapatechnologies.com?subject=Other Email on BAPAGames.com.&body=%0A%0A%0A%0A%0A--------------------------------%0A--Please don't remove below content--%0ADevice summary:%0AUser ID: " + this.props.game?.user?.uid;
  }
  privacy(){
    this.props.showHeaderOptions('');
    this.setState({ privacyShow: true });
  }
  closePrivacy(){
    this.setState({ privacyShow: false });
  }
  support(){
    this.props.showHeaderOptions('');
    this.props.history.push("/support");
  }
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        path={this.props.path}
        render={matchProps => (
          <React.Fragment>
            <div className="public_page">
              <div className='public_page_header'>
                <Header />
              </div>
              <div className='public_page_body'>
                <Component {...matchProps} />
              </div>
            </div>
            {
              this.props.app.showProgressBar &&
              <div className={classNames("pop_popup_background")} style={{ zIndex: 199, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '74px' }}>
                <div style={{ zIndex: 200, width: '200px', height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <img src={loading} style={{ width: '100%', height: '100%' }} alt="loading..." />
                </div>
              </div>
            }
            <Popup open={this.props.app.showHeaderOption === "settings"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
              <div className='message_over'>
                <div className='result_rows'>
                  <h3>SETTINGS</h3>
                </div>
                <div className='settings_grid'>
                  {
                    [
                      { name: "User ID", value:this.props.game?.user?.uid }, 
                      { name: "Feedback", value:"Click here", onClick: ()=> this.feadback() }, 
                      { name: "Email Us", value:"Click here", onClick: ()=> this.email()}, 
                      { name: "Privacy Preference", value:"Click here", onClick: ()=> this.privacy()}, 
                      { name: "Support", value:"Click here", onClick: ()=> this.support()}
                    ].map((x,i)=>{
                      return(
                        <div key={i} className='settings_item'>
                          <div className='setting_item_name'>{x.name}</div>
                          <div className='setting_item_value'><span onClick={x.onClick && x.onClick}>{x.value}</span></div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className='version_info'>
                  Site Version: {this.props.app.appVersion}
                </div>
              </div>
            </Popup>
            <Popup open={this.props.app.showHeaderOption === "setting_clear"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
              <div className='message_over'>
                <div className='result_rows'>
                  <h3>CLEAR CACHE</h3>
                </div>
                <div className='result_rows'>
                  <div className='result_flex'>

                  </div>
                </div>
                {
                  <div className='result_rows'>
                    <div className='ng_action_buttons'>
                      <div className='ng_action_button' style={{ color: "#cf222e" }} onClick={() => { this.clearDataInThisBrowser() }}>
                        <div style={{ margin: '0 30px' }}>
                          Clear Data in this Browser
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Popup>
            {
              this.props.location.pathname !== "/privacypolicy" && 
              <PrivacyNotice show={this.state.privacyShow} close={()=>{this.closePrivacy()}}/>
            }
          </React.Fragment>
        )}
      />
    );
  }
}

export const PublicRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(_PublicRoute)
);