import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Thirukkural.css';
import { GetArrangeLeaderBoard, OpenPopup, SelectPlayer, ShowHeaderOptions, StoreThirukkuralGameData, UpdateArrangeLeaderBoard } from '../../Actions';
import kural from '../../Asset/Kural.json';
import classNames from 'classnames';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { Popup } from '../../Components';
import { decryptWithAES, encryptWithAES, generateRandomNumber } from '../../Utils/CommonFunctions';
import Timer from '../../Components/BasicComponents/Timer';

var gameTitle = 'THIRUKKURAL';
var gameTitleInTamil = 'திருக்குறள்';
var gameName = 'BAPA_GAMES_' + gameTitle;

function mapStateToProps({ app, game }) {
    return { app, game };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p =>{
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        },
        getLeaderboard: data => {
            dispatch(GetArrangeLeaderBoard(data));
        },
        updateLeaderboard: (progress, data) => {
            dispatch(UpdateArrangeLeaderBoard(progress, data));
        },
        storeThirukkuralGameData: (result, place, name) => {
            dispatch(StoreThirukkuralGameData(result, place, name));
        },
    };
};

class _Thirukkural extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.randomPattern = [0,1,2,3,4,5,6];
        this.state = {
            todayKural: -1,
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            answer: "",
            kural: "",
            dragStart: null,
            onhoverbox: null,
            dragFindText: false,
            drops: ["","","","","","",""],
            colors: ["","","","","","",""],
            toastMessage: "",
            showToast: false,
            gameOver: false,
            screenX: -1,
            screenY: -1,
            completed: false
        }
    }
    componentDidMount(){
        this.windowResize();
        window.addEventListener('load', ()=>this.windowResize());
        window.addEventListener('resize', ()=>this.windowResize());
        this.loadKural();
    }
    componentDidUpdate(){
        this.loadKural();
    }
    loadKural(){
        var word = this.getData();
        var gameData = decryptWithAES(localStorage.getItem(gameName));
        var eRes = ["","","","","","",""];
        if (gameData !== null){
            if (gameData.store?.Day === word.Day){
                eRes = gameData.store?.existingResult;
            } else {
                gameData["store"] = { Day : word.Day, existingResult : eRes, trial: 0 };
            }
        } else {
            gameData = { store: { Day : word.Day, existingResult : eRes, trial: 0 }};
        }
        if (this.state.todayKural !== word?.Kural){
            var kural = this.makeRandomPattern(word?.Kural);
            if (gameData?.store?.Day === word.Day){
                this.setState({ todayKural: word?.Kural, kural: kural.kural, answer: kural.answer, drops: gameData?.store?.existingResult, completed: kural.answer === gameData?.store?.existingResult?.reduce((p,n)=> {return p + ":" + n;}) });
            } else {
                this.setState({ todayKural: word?.Kural, kural: kural.kural, answer: kural.answer });
            }
            setTimeout(() => {
                var given = this.state.answer.split(':');
                var find = this.state.drops;
                var colors = [];
                find.forEach((x,i)=>{
                    if (x === ""){
                        colors.push("");
                    }else if (given[i] === x){
                        colors.push("pass");
                    } else {
                        colors.push("fail");
                    }
                });
                this.setState({ colors: colors, completed: kural.answer === gameData?.store?.existingResult?.reduce((p,n)=> {return p + ":" + n;}) });
                if (kural.answer === gameData?.store?.existingResult?.reduce((p,n)=> {return p + ":" + n;})){
                    this.props.showHeaderOptions('leaderboard');
                }
            }, 300);
        }
    }
    getData() {
        if (this.props.game?.todayData) {
            return decryptWithAES(this.props.game.todayData);
        } else return null;
    }
    getRunningData() {
        if (this.props.game?.thirukkural) {
            return decryptWithAES(this.props.game.thirukkural);
        } else return null;
    }
    makeRandomPattern(kur){
        var done = false;
        do{
            var num = generateRandomNumber(0,7);
            var mid = this.randomPattern[num];
            this.randomPattern[num] = this.randomPattern[0];
            this.randomPattern[0] = mid;
            done = true;
            done = this.randomPattern.filter((x,i) => { return x === i;}).length === 0;
        } while (!done);
        if (kural["kural_" + kur]){
            var split = kural["kural_" + kur].split(":");
            var newK = "";
            this.randomPattern.forEach((x,i)=>{
                if (i !== 0) newK += ":";
                newK += split[x];
            });
            return { kural: newK, answer: kural["kural_" + kur] };
        }
    }
    windowResize(){
        this.setState({ innerWidth : window.innerWidth, innerHeight: window.innerHeight});
    }
    checkAnswer(update){
        if (this.state.drops.includes("")){
            update && this.setState({ showToast: true, toastMessage: "ஏழு வார்த்தைகளையும் வரிசைப்படுத்துங்கள்" });
            setTimeout(() => {
                this.setState({ showToast: false });
            }, 2000);
        } else {
            var word = this.getData();
            var gameData = decryptWithAES(localStorage.getItem(gameName));
            var eRes = [];
            if (gameData !== null){
                if (gameData.store?.Day === word.Day){
                    eRes = gameData.store?.existingResult;
                } else {
                    gameData["store"] = { Day : word.Day, existingResult : eRes, trial: 0 };
                }
            } else {
                gameData = { store: { Day : word.Day, existingResult : eRes, trial: 0 }};
            }
            if (this.state.answer === this.state.drops.reduce((p,n)=> {return p + ":" + n;})){
                this.setState({ colors: ["pass","pass","pass","pass","pass","pass","pass"], gameOver: true });
                setTimeout(() => {
                    this.setState({ completed: true });
                    this.props.showHeaderOptions('leaderboard');
                }, 1000);
                gameData.count = gameData.count + 1;
                if (!gameData.guess){
                    gameData.count = 0;
                    gameData.guess = { count1: 0, count2: 0, count3: 0, count4: 0, count5: 0 }
                }
                var trial = gameData.store.trial + 1;
                if (trial >= 5){
                    trial = 5;
                }
                gameData.count = gameData.count + 1;
                gameData.guess["count" + trial] = gameData.guess["count" + trial] + 1;
            } else {
                var given = this.state.answer.split(':');
                var find = this.state.drops;
                var colors = [];
                find.forEach((x,i)=>{
                    if (given[i] === x){
                        colors.push("pass");
                    } else {
                        colors.push("fail");
                    }
                });
                this.setState({ colors: colors });
            }
            gameData.store.trial++;
            gameData.store.existingResult = this.state.drops;
            localStorage.setItem(gameName, encryptWithAES(gameData));
            // update && this.props.storeThirukkuralGameData(this.state.answer === this.state.drops.reduce((p,n)=> {return p + ":" + n;}), this.state.drops, gameName);
        }
    }
    allowdrop(e){
        e.preventDefault();
        this.setState({ onhoverbox: e.target.id.replace("find","") });
    }
    leavedrag(e){
        e.preventDefault();
        this.setState({ onhoverbox: null });
    }
    dragstart(e){
        this.setState({ onhoverbox: null, dragStart: e.id });
    }
    dragging(){
    }
    dragstop(){
    }
    drop(e, i){
        if (!this.state.completed){
            if (e.dragData.type === "find"){
                var drops = JSON.parse(JSON.stringify(this.state.drops));
                drops[e.dropData.id] = e.dragData.value;
                drops[e.dragData.id] = e.dropData.value;
                this.setState({ onhoverbox: null, dragStart: null, drops: drops, colors: ["","","","","","",""] });
            } else{
                var dropss = JSON.parse(JSON.stringify(this.state.drops));
                dropss[e.dropData.id] = e.dragData.value;
                this.setState({ onhoverbox: null, dragStart: null, drops: dropss, dragFindText: false, colors: ["","","","","","",""] });
            }
        }
    }
    leaderboard() {
        var store = localStorage.getItem(gameName);
        var guess = [{ count: 0, persentage: '7%', name: 'count1', title: '1' }, { count: 0, persentage: '7%', name: 'count2', title: '2' }, { count: 0, persentage: '7%', name: 'count3', title: '3' }, { count: 0, persentage: '7%', name: 'count4', title: '4' }, { count: 0, persentage: '7%', name: 'count5', title: '5+' }];
        var average = 0;
        if (store !== null && store !== undefined) {
            var result = decryptWithAES(store);
            if (result.guess) {
                var max = "count1";
                Object.keys(result.guess).forEach(key => {
                    if (result.guess[key] > result.guess[max]) {
                        max = key;
                    }
                });
                guess.forEach((item, i) => {
                    guess[i].count = result.guess[guess[i].name];
                    if (item.count === result.guess[max] && item.count !== 0) {
                        guess[i].persentage = '100%';
                    } else if (item.count === 0) {
                        guess[i].persentage = '7%';
                    } else {
                        guess[i].persentage = ((result.guess[guess[i].name] * 100) / result.guess[max]) + '%';
                    }
                });
                guess.forEach((item, i) => {
                    if (i !== 0) {
                        average = average + (i * item.trial);
                    }
                });
            }
            result['guess'] = guess;
            return result;
        } else {
            return {
                count: 0,
                guess: guess
            };
        }
    }
    RowFr(s) {
        var fr = "";
        for (var i = 0; i < s; i++) {
            fr = fr + '1fr ';
        }
        return fr;
    }
    ShareText(data) {
        if (data){
            var kural = this.state.kural.split(':');
            var newLine = `
`;
            var result = gameTitleInTamil + ' ' + newLine + newLine;
            result = result + "முயற்சி: " + data?.store?.trial + newLine;
            result = result + kural[0] + " " + kural[1] + " " + kural[2] + " " + kural[3] + newLine;
            result = result + kural[4] + " " + kural[5] + " " + kural[6] + "." + newLine;
            result = result + newLine + "https://bapagames.com/single/thirukkural";
            navigator.clipboard.writeText(result);
            this.setState({ showToastShare: true });
            setTimeout(() => {
                this.setState({ showToastShare: false });
            }, 3000);
        }
    }
    render() {
        var word = this.getData();
        var gameData = decryptWithAES(localStorage.getItem(gameName));
        var leaderboard = this.leaderboard();
        if (this.state.todayKural !== -1){
            return (
                <React.Fragment>
                    <div className='kural_container'>
                        <div className='toast_message' style={{ display: this.state.showToast ? 'block' : 'none' }}>{this.state.toastMessage}</div>
                        <div className='kural_game_container'>
                            <div className='kural_find_container'>
                                <div style={{display:'block', width: '100%'}}>
                                    {
                                        (gameData?.store?.Day === word.Day && gameData?.store?.trial > 0)&& 
                                        <div className='trail_count'>
                                            {"முயற்சி: " + JSON.stringify(gameData?.store?.trial)}
                                        </div>
                                    }
                                    <div className='kural_find_wordlist'>
                                        {
                                            this.state.drops.map((x,i)=>{
                                                return(
                                                    <div key={i} className='kural_find_word'>
                                                        <DragDropContainer targetKey="kural" disabled={this.state.drops.includes(x)}
                                                            dragData={{ id: i, value : x, type: "find" }}
                                                            onDrop={(e)=>this.drop(e, i)}
                                                            onDragStart={(e)=> !this.state.drops.includes(x) && this.dragstart(e, i)} 
                                                            onDrag={(e)=>this.dragging(e, i)}
                                                            onDragEnd={(e)=>this.dragstop(e, i)}>
                                                            <DropTarget targetKey="kural" 
                                                                dropData={{ id: i, value : x }} 
                                                                    onDragEnter={(e)=>this.allowdrop(e)}
                                                                    onDragLeave={(e)=>this.leavedrag(e)}>
                                                                <div className={classNames('kural_find_word_btn', 'font_size', this.state.onhoverbox === (i+ "") && 'kural_find_word_allow', `kural_find_word_${this.state.colors[i]}`)} draggable={x!=="" && !this.state.gameOver}>
                                                                    {x}
                                                                </div>
                                                            </DropTarget>
                                                        </DragDropContainer>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                                        <div className={classNames('kural_check_btn', 'font_size', this.state.completed && 'btn_disable')} onClick={()=>{ !this.state.completed && this.checkAnswer(true)}}>
                                            சரிபார்
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'kural_given_wordlist'}>
                                {
                                    this.state.kural.split(':').map((x,i)=>{
                                        return(
                                            <div key={i} className='kural_given_word'>
                                                {
                                                    !this.state.drops.includes(x) ?
                                                    <DragDropContainer targetKey="kural"
                                                        dragData={{ id: i, value : x, type: "given" }}
                                                        onDrop={(e)=> !this.state.drops.includes(x) && this.drop(e, i)}
                                                        onDragStart={(e)=> !this.state.drops.includes(x) && this.dragstart(e, i)} 
                                                        onDrag={(e)=> !this.state.drops.includes(x) && this.dragging(e, i)}
                                                        onDragEnd={(e)=> !this.state.drops.includes(x) && this.dragstop(e, i)}>
                                                        <div className={classNames('kural_given_word_btn', 'font_size')}>{x}</div>
                                                    </DragDropContainer>:
                                                    <div className={classNames('kural_given_word_btn', 'font_size', this.state.drops.includes(x) && 'btn_disable')}>{x}</div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <Popup open={this.props.app.showHeaderOption === "leaderboard"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                        <div className='message_over'>
                            <div className='result_rows'>
                                <h3>புள்ளிவிவரங்கள்</h3>
                            </div>
                            <div className='result_rows'>
                                <div className='result_flex'>
                                    <div className='stat_column'>
                                        <div className='stat_count'>{gameData?.count || "0"}</div>
                                        <div className='stat_name'>விளையாடியவை</div>
                                    </div>
                                    {/* <div className='stat_column'>
                                        <div className='stat_count'>{((result.sum / result.count || 0) + "").substring(0, 3)}</div>
                                        <div className='stat_name'>சராசரி</div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='result_rows'>
                                <h3>புள்ளியியல் பட்டியல்</h3>
                            </div>
                            <div className='result_rows_guess'>
                                {
                                    leaderboard.guess.map((x, i) => {
                                        var lasgamecount = gameData?.store?.trial || 0;
                                        if (gameData?.store?.trial >= 5){
                                            lasgamecount = 5;
                                        }
                                        return (
                                            <div key={i} className='guess_count'>
                                                <div className='guess_count_number'>{x.title}</div>
                                                <div className='guess_progress_container'>
                                                    <div className='guess_progress' style={{ width: x.persentage, backgroundColor: (i + 1) === lasgamecount ? "#ff7126" : "" }}>
                                                        <div className='player_guess'>{x.count}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.completed &&
                                <div className='result_rows'>
                                    <div className='ng_action_buttons'>
                                        <div className='ng_action_timer'>
                                            <span>{"அடுத்த குறள்:"}</span><br />
                                            <div id="timer" style={{ fontSize: '24px' }}>
                                                <Timer></Timer>
                                            </div>
                                        </div>
                                        <div className='ng_action_button' onClick={() => this.ShareText(gameData)}>
                                            {"பகிர்"}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Popup>
                    <Popup open={this.props.app.showHeaderOption === "instruction"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                        <div className='howtoplay'>
                            <div className='howtoplay_title'>
                                <h3>எப்படி விளையாடுவது?</h3>
                            </div>
                            <div className='howtoplay_intro'>
                                <p>கொடுக்கபட்ட ஏழு வார்த்தைகளை வரிசைபடுத்தி <span className='howtoplay_bold'>{gameTitleInTamil}</span> ஐ யூகிக்கவும். ஒவ்வொரு நாளும் ஒரு புதிய <span className='howtoplay_bold'>{gameTitleInTamil}</span> கிடைக்கும்!</p><br/>
                                <p>காலியான பெட்டிகளில் வார்த்தையை இழுத்து விட்டு, சமர்ப்பிக்க சரிபார் பொத்தானை அழுத்தவும்.</p><br/>
                                <p>ஒவ்வொரு யூகத்திற்கு பிறகும், உங்கள் வார்த்தைகள் சரியான இடத்தில் உள்ளதா என்பதைக் கட்டத்தின் நிறம் காட்டும். பச்சை நிறம் சரியான இடத்தில் உள்ளது என்றும், சிவப்பு நிறம் தவறான இடத்தில் உள்ளது என்பதை குறிக்கும்</p><br/>
                            </div>
                        </div>
                    </Popup>
                </React.Fragment>
            );
        } else {
            return(
                <div></div>
            );
        }
    }
}

export const Thirukkural = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Thirukkural)
);