import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './HomePage.css';
import { OpenPopup, SelectGame, SelectPlayer } from '../../Actions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p =>{
            dispatch(SelectPlayer(p));
        },
        selectGame: g =>{
            dispatch(SelectGame(g));
        },
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _HomePage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    GoToGame(name){
        this.props.history.push('/' + name);
    }
    componentDidMount(){
        this.redirect();
    }
    componentDidUpdate(prevProps){
        this.redirect();
    }
    redirect(){
        if (this.props.location.pathname === "/"){
            this.GoToGame('single');
        }
    }
    render() {
        return (
            <div className="home_selection">
                <div className='home_select_button' onClick={()=>this.GoToGame('single')}>
                    <div>Single Player</div>
                </div>
                <div className='home_select_button' onClick={()=>this.GoToGame('two')}>
                    <div>Two Player</div>
                </div>
                <div className='home_select_button' onClick={()=>this.GoToGame('multi')}>
                    <div>Multi Player</div>
                </div>
            </div>
        );
    }
}

export const HomePage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_HomePage)
);