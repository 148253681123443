import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './PrivacyNotice.css';
import { OpenPopup } from '../../Actions';
import { SwitchButton } from '../../Components/BasicComponents/SwitchButton';
import { Icon } from '../../Components';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
    };
};

class _PrivacyNotice extends Component {
    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        openPopup: PropTypes.func,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.name = 'privacy.bapagames.com';
        this.custom = 'custom-privacy.bapagames.com';
        this.value = ["All","Custom"];
        this.state = {
            openPreferences: false,
            analytical: true,
            marketing: true
        }
    }
    componentDidMount(){
        var setting = window.localStorage.getItem(this.custom);
        var type = window.localStorage.getItem(this.name);
        if (type === "All"){
            this.setState({openPreferences: true, analytical: true, marketing: true});
        } else {
            var data = JSON.parse(setting);
            if (data){
                this.setState({analytical: data.analytical, marketing: data.marketing});
            }
        }
    }
    save = () => {
        window.localStorage.setItem(this.name, this.value[1]);
        window.localStorage.setItem(this.custom, JSON.stringify({ analytical: this.state.analytical, marketing: this.state.marketing }));
        this.setState({ openPreferences: false });
        this.props.close()
    }
    accept = () =>{
        window.localStorage.setItem(this.name, this.value[0]);
        this.setState({ openPreferences: false });
        this.props.close();
    }
    render() {
        if (!this.value.includes(window.localStorage.getItem(this.name)) || this.props.show){
            return (
                <div className="privacy_notice_container">
                    <div className="privacy_notice_popup">
                        {
                            this.props.show &&
                            <div className='privacy_notice_close'>
                                <div className='privacy_notice_close_icon' onClick={()=>{ this.props.close()}}>
                                    <Icon>close</Icon>
                                </div>
                            </div>
                        }
                        <h4 style={{margin:"2px"}}>We care about your privacy</h4>
                        <p className='privacy_notice_text'>
                            To give you the best possible experience on our website, we use cookies. By clicking “Accept“, you agree to our website's cookie use as described in our Cookie Policy. 
                            Mandatory cookies are needed for the functionality of the site; other cookies are used for performance and marketing purposes. 
                            You can change your cookie settings at any time by clicking “Preferences.”
                        </p>
                        {
                            (this.state.openPreferences || this.props.show) &&
                            <React.Fragment>
                                <div className='privacy_notice_cutom_item'>
                                    <div className='privacy_notice_custom_content'>
                                        <div className='privacy_notice_custom_heading'>Essential</div>
                                        <div className='privacy_notice_text'>{'These cookies are needed in order for the website to function and can\'t be turned off.'}</div>
                                    </div>
                                    <div className='privacy_notice_custom_action'>
                                        <div className='privacy_notice_custom_action_always'>Always Active</div>
                                    </div>
                                </div>
                                <div className='privacy_notice_cutom_item'>
                                    <div className='privacy_notice_custom_content'>
                                        <div className='privacy_notice_custom_heading'>Analytical cookies</div>
                                        <div  className='privacy_notice_custom_sub_heading'>GOOGLE ANALYTICS</div>
                                        <div className='privacy_notice_text'>Analytical cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</div>
                                    </div>
                                    <div className='privacy_notice_custom_action'>
                                        <SwitchButton name="analytical" onChange={(e)=>{ this.setState({ [e.target.name]: e.target.value })}} value={this.state.analytical}/>
                                    </div>
                                </div>
                                <div className='privacy_notice_cutom_item'>
                                    <div className='privacy_notice_custom_content'>
                                        <div className='privacy_notice_custom_heading'>Marketing cookies</div>
                                        <div  className='privacy_notice_custom_sub_heading'>GOOGLE ADS</div>
                                        <div className='privacy_notice_text'>Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</div>
                                    </div>
                                    <div className='privacy_notice_custom_action'>
                                        <SwitchButton name="marketing" onChange={(e)=>{ this.setState({ [e.target.name]: e.target.value })}} value={this.state.marketing}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className='privacy_notice_action'>
                            <div className='privacy_notice_action_btn' onClick={()=> window.open("/privacypolicy", "_blank")}>Privacy Policy</div>
                            {!this.props.show && <div className='privacy_notice_action_btn' onClick={()=> this.setState({ openPreferences: !this.state.openPreferences })}>Preferences</div>}
                            {(this.state.openPreferences || this.props.show) && <div className='privacy_notice_action_btn' onClick={()=> { this.save() }}>Save</div>}
                            <div className='privacy_notice_action_btn' onClick={()=> {this.accept()}}>Accept All</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export const PrivacyNotice = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_PrivacyNotice)
);