import { AppActions } from '../Actions';

const initialState = {
  appVersion: "v1.1.0",
  showHeaderOption: "",
  player: "",
  game: "",
  openPages: ["/not-found","/","/admin/console","/about","/privacypolicy"],
  login: "/",
  home: "/home",
  language: localStorage.getItem('language') || 'EN',
  openPopup: '',
  showProgressBar: false,
  pageState: 100,
  errorCode: {
    response: '',
    request: ''
  },
  messages: [],
  localProgress: []
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.SELECT_PLAYER: {
      return {
        ...state,
        player: action.payload.player
      };
    }
    case AppActions.SELECT_GAME: {
      return {
        ...state,
        game: action.payload.game
      };
    }
    case AppActions.LOAD: {
      return {
        ...state,
        ...action.payload
      };
    }
    case AppActions.ERROR: {
      return {
        ...state,
        errorCode: action.payload
      };
    }
    case AppActions.RESET_ERR: {
      return {
        ...state,
        errorCode: { response: '', request: '' } 
      };
    }
    case AppActions.SHOW_PB: {
      return {
        ...state,
        showProgressBar: true
      };
    }
    case AppActions.HIDE_PB: {
      return {
        ...state,
        showProgressBar: false
      };
    }
    case AppActions.POPUP: {
      return {
        ...state,
        openPopup: state.openPopup === action.payload ? '' : action.payload,
        errorCode: { response: '', request: '' },
        failmessage: ''
      };
    }
    case AppActions.LANG: {
      sessionStorage.setItem('language', action.payload);
      return {
        ...state,
        language: action.payload
      };
    }
    case AppActions.REG_FAIL: {
        return {
            ...state,
            failmessage: action.payload
        };
    }
    case AppActions.HEADER_OPTION: {
        return {
            ...state,
            showHeaderOption: action.payload
        };
    }
    default:
      return state;
  }
};
