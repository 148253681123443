import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './common.css';
import App from './App';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import locale from './locale/locale.json';
import reportWebVitals from './reportWebVitals';
import GlobalFonts from './fonts/font';
import store from './store';

i18next.init({
    lng: '_default',
    resources: {
        _default: {
            translation: locale
        }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <GlobalFonts />
        <App />
    </Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
