import { createGlobalStyle } from 'styled-components';

import NameOfYourFontWoff from './web-Bold.woff';
import NameOfYourFontWoff2 from './web-Bold.woff2';
import tamilFont from './Uni Ila.Sundaram-01.ttf';
import KavivanartamilFont from './Kavivanar-Regular.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Philosopher';
        src: local('Philosopher'), local('Philosopher'),
        url(${NameOfYourFontWoff2}) format('woff2'),
        url(${NameOfYourFontWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    },
    @font-face {
        font-family: "Uni Ila.Sundaram-01";
        src: local("Uni Ila.Sundaram-01"),
         url(${tamilFont}) format("truetype");
        font-weight: bold;
    },
    @font-face {
        font-family: "Kavivanar";
        src: local("Kavivanar"),
        url(${KavivanartamilFont}) format("truetype");
        font-weight: bold;
    }
`;