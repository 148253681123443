import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './TextBox.css'
import { Icon } from './Icon';
import i18next from 'i18next';

function mapStateToProps({ field }) {
  return {
    validation: field
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
};

class _TextBox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const className = classNames(
      "txt_container", "txt_"+ (this.props.boxsize || "full"), {
        "Disabled": this.props.disabled,
        "txt_error": (this.props.validation && this.props.validation[this.props.name]?.length > 0)
      },
      this.props.className
    );
    const placeholder = (this.props.placeholder ? this.props.placeholder : "") + (this.props.optional ? ' (Optional)' : '');
    const disabled = this.props.disabled && true ? 'disabled' : false;

    return (
      <React.Fragment>
        <div className={className} style={this.props.style}>
          {this.props.title && <p className={classNames("txt_information_text")}>{this.props.title}</p>}
          {
            (this.props.type === "text" || this.props.type === "password") &&
              <input
              className={classNames("txt_input", this.props.class, {"txt_error" : (this.props.validation && this.props.validation[this.props.name])})}
              name={this.props.name}
              type={this.props.type}
              onBlur={event => {
                if (this.props.onBlur) this.props.onBlur(event);
              }}
              onChange={event => {
                if (this.props.onChange) this.props.onChange(event);
              }}
              value={this.props.value || ''}
              placeholder={placeholder ? placeholder : ""}
              disabled={disabled}
              pattern={this.props.regExPattern}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  if (this.props.onSubmit) this.props.onSubmit(e);
                }
              }}
              autoComplete={"off"}
            />
          }
          {
            this.props.type === "textarea" &&
              <textarea
              className={classNames("txt_area", {"txt_error" : (this.props.validation && this.props.validation[this.props.name]?.length > 0)})}
              name={this.props.name}
              onBlur={event => {
                if (this.props.onBlur) this.props.onBlur(event);
              }}
              onChange={event => {
                if (this.props.onChange) this.props.onChange(event);
              }}
              value={this.props.value || ''}
              placeholder={placeholder ? placeholder : ""}
              disabled={disabled}
              pattern={this.props.regExPattern}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  if (this.props.onSubmit) this.props.onSubmit(e);
                }
              }}
              // autoComplete={"off"}
            />
          }
          {
            (this.props.validation && this.props.validation[this.props.name]?.length > 0) &&
            <div className='txt_error_icon' title={i18next.t('validationMsg.'+ this.props.validation[this.props.name])}>
              <Icon>error</Icon>
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export const TextBox = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(_TextBox)
);