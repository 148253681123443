import React from 'react';
import PropTypes from 'prop-types';
import './SwitchButton.css';

export class SwitchButton extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  };
  change = (e) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: e.target.checked
      }
    });
  }
  render() {
    return (
      <div className="switch-group">
        <label className="switch">
          <input className="category-onoff" type="checkbox" data-category="functionalv2" style={{ display: "none" }} onChange={(e)=>this.change(e)} checked={this.props.value}/>
          <span className="switch__slider"></span>
        </label>
      </div>
    );
  }
}
