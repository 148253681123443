import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './InsertWord.css';
import { InsetWordToFireBaseDataBase } from '../../Actions';
import { Icon } from '../../Components';
import moment from 'moment';

var textList = [
];

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
        insetData: data => {
            dispatch(InsetWordToFireBaseDataBase(data));
        }
    };
};

class _InsertWord extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    insertSingleWord(){
        var text = document.getElementById("tamileeinput").value;
        document.getElementById("tamileeinput").value = "";
        if (this.splitText(text).split('|').length === 5){
            this.props.insetData({
                Type: "Single",
                Word: text,
            });
        } else {
            document.getElementById("errorMessage_insert").innerText = "More that 5 characters or empty!!!";
        }
    }
    insertWord() {
        var startingDate = moment("2022-03-10");
        var number = 1;
        textList.forEach((x, i) => {
            var duplicate = false;
            for (var z = 0; z < i; z++) {
                if (x === textList[z]) {
                    console.log("duplicate", x);
                    duplicate = true
                }
            }
            if (!duplicate) {
                this.props.insetData({
                    Day: number,
                    Word: x,
                    Date: ("0" + (startingDate.month() + 1)).slice(-2) + "-" + ("0" + startingDate.date()).slice(-2)
                });
                startingDate.add(1, 'days');
                number++;
            } else {
                console.log(duplicate, x);
            }
        })
    }
    splitText(text) {
        var newText = "";
        var half = false;
        text.split("").reverse().forEach((x, i) => {
            if (["்", "ா", "ி", "ீ", "ு", "ூ", "ெ", "ே", "ை", "ொ", "ோ", "ௌ"].includes(x)) {
                half = true;
                newText = x + newText;
            } else if (half) {
                newText = "|" + x + newText;
                half = false;
            } else {
                newText = "|" + x + newText;
            }
        });
        return newText.substring(1);
    }
    render() {
        return (
            <React.Fragment>
                <div className='howtoplay'>
                    <div className='howtoplay_title'>
                        <h3>Enter New Words to Firebase</h3>
                        {
                            this.props.app.showHeaderOption === "instruction" &&
                            <div className='howtoplay_close' onClick={() => { this.props.showHeaderOptions('') }}>
                                <Icon>close</Icon>
                            </div>
                        }
                    </div>
                    <div className='howtoplay_intro'>
                        <input id="tamileeinput" type="text" style={{ margin:'30px 0',width: '100%', height: '50px' }}/>
                    </div>
                    <div className='howtoplay_intro'>
                        <div id={"errorMessage_insert"}></div>
                    </div>
                    <div className='howtoplay_intro lineover'>
                        <div className='link_button btn_start' onClick={() => { this.insertSingleWord(); }}>
                            {'Insert >'}
                        </div>
                        <div className='header_empty_middle'></div>
                        <div className='link_button btn_start' onClick={() => { this.insertWord(); }}>
                            {'Bulk Insert >'}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export const InsertWord = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_InsertWord)
);