import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './SupportPage.css';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

class _SupportPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    goHome(){
        this.props.history.push("/");
    }
    render() {
        return (
            <div className="container">
                <div className="nf_container">
                    <div className="form_center">
                        <div className="center margin30">
                            <div className="support_title margin30">Support</div>
                            <div className="support_subtitle margin30">We're here to assist you!</div>
                            <div className="support_message margin30">Please continue to send us your feedback, bug reports, and feature requests to <a href='mailto:neethirajan.lawking@gmail.com' className='support_email_link'>neethirajan.lawking@gmail.com</a></div>
                            <div className="support_home margin30" onClick={()=>this.goHome()}>Go to Home</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const SupportPage = withRouter(
    connect(
        mapStateToProps,
        null
    )(_SupportPage)
);