import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AccountDeletePage.css';
import { Select, TextBox } from '../../Components';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

class _AccountDeletePage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    goAheadAndDelete(){
        alert("Your account deletion request submitted successfully. It will be done in 7 days.");
    }
    render() {
        return (
            <div className="container">
                <div className="nf_container">
                    <div className="form_center">
                        <div className="center margin30">
                            <div className="support_title margin30">Delete Account</div>
                            <div className="support_subtitle margin30">We're sorry to see you leaving</div>
                            <div className="support_message margin30">When you delete your account, your profile, game points and your purchase details will be permanently removed.</div>
                            <div>
                                <TextBox name="app" type="text" value={""} title={<span>{"Enter you e-mail address or player id: "}<span style={{color:"red"}}>*</span></span>} onChange={(e)=>this.updateBuildStep(e)}></TextBox>
                            </div>
                            <div>
                                <Select name="app" type="text" value={""} title={<span>{"Select the App for your account deletion: "}<span style={{color:"red"}}>*</span></span>} options={[{text:"--Select--", value:""},{text:"5 Cards", value:"fiveCards"},{text:"வார்த்தை விளையாட்டு 1", value:"wordgame1"}]} onChange={(e)=>this.updateBuildStep(e)}></Select>
                            </div>
                            <div className="delete_action margin30" onClick={()=>this.goAheadAndDelete()}>Goahead and Delete</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const AccountDeletePage = withRouter(
    connect(
        mapStateToProps,
        null
    )(_AccountDeletePage)
);