import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './FindTheWord.css';
import { OpenPopup, SelectPlayer, ShowHeaderOptions, SilentUpdateTamilee } from '../../Actions';
import { Icon, Popup } from '../../Components';
import Timer from '../../Components/BasicComponents/Timer';
import classNames from 'classnames';
import { decryptWithAES, encryptWithAES } from '../../Utils/CommonFunctions';

var timer = null;
var gameTitle = 'TAMILSOL';
var gameTitleInTamil = 'தமிழ்ச் சொல்';
var gameName = 'BAPA_GAMES_' + gameTitle;

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p => {
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        silentUpdateTamilee: data => {
            dispatch(SilentUpdateTamilee(data));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        }
    };
};

class _FindTheWord extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.gridSize = 5;
        this.processrow = -1;
        this.processcol = -1;
        this.colorCode = {
            correctplaceandword: "#6fc772",
            correctplaceandwordpartial: "#f2973a",
            correctwordnotplace: "#f2f249",
            correctwordpartialnotplace: "#ed544e",
            notcorrect: "gray"
        };
        this.colorCodeShare = {
            correctplaceandword: "🟩",
            correctplaceandwordpartial: "🟧",
            correctwordnotplace: "🟨",
            correctwordpartialnotplace: "🟥",
            notcorrect: "⬛️",
            empty: "⬜️"
        };
        this.state = {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            text: "",
            clue: [false, false]
        }
    }
    startGame(){
        this.setState({ showIntro: false });
    }
    componentDidMount() {
        this.windowResize();
        window.addEventListener('load', ()=>this.windowResize());
        window.addEventListener('resize', ()=>this.windowResize());
        document.addEventListener('keydown', (e)=>this.keyEvents(e));
        setTimeout(() => {
            var data = this.createGameData();
            if (data?.done){
                this.props.showHeaderOptions('leaderboard');
            }
        }, 2000);
    }
    keyEvents(e){
        if (e.keyCode === 8 || e.key === 'Enter'){
            var data = this.createGameData();
            if (e.keyCode === 8) {
                this.remove();
            }
            if (e.key === 'Enter') {
                this.check(data);
            }
        }
    }
    windowResize(){
        this.setState({ innerWidth : window.innerWidth, innerHeight: window.innerHeight});
    }
    createArrayOfSize(size) {
        var array = [];
        for (var i = 0; i < size; i++) {
            array.push(false);
        }
        return array;
    }
    splitText(text) {
        var newText = "";
        var half = false;
        text.split("").reverse().forEach((x, i) => {
            if (["்", "ா", "ி", "ீ", "ு", "ூ", "ெ", "ே", "ை", "ொ", "ோ", "ௌ"].includes(x)) {
                half = true;
                newText = x + newText;
            } else if (half) {
                newText = "|" + x + newText;
                half = false;
            } else {
                newText = "|" + x + newText;
            }
        });
        return newText.substring(1);
    }
    generateRandomNumber = (min, max) => {
        var random = Math.random() * (max - min) + min;
        return parseInt(random, 10);
    }
    keyClick(x, row, data) {
        var lastletter = this.state.text.split('')[this.state.text.split('').length -1];
        if (data?.done) return;
        if (x === "்" && (this.state.text === "" || ["்", "அ", "ஆ", "இ", "ஈ", "உ", "ஊ", "எ", "ஏ", "ஐ", "ஒ", "ஓ", "ஔ", "ஃ"].includes(lastletter))) return;
        var newText = "";
        if (row > 1 && ["க", "ங", "ச", "ஞ", "ட", "ண", "த", "ந", "ப", "ம", "ய", "ர", "ல", "வ", "ழ", "ள", "ற", "ன"].includes(this.state.text.substring(this.state.text.length - 1))) {
            newText = this.state.text + this.gethalfText(x);
        } else {
            newText = this.state.text + x;
        }
        if (data?.array[0].length >= this.splitText(newText).split('|').length) {
            this.setState({ text: newText });
        }
    }
    RowFr(s) {
        var fr = "";
        for (var i = 0; i < s; i++) {
            fr = fr + '1fr ';
        }
        return fr;
    }
    gethalfText(x) {
        switch (x) {
            case "்": return "்";
            case "அ": return "அ";
            case "ஆ": return "ா";
            case "இ": return "ி";
            case "ஈ": return "ீ";
            case "உ": return "ு";
            case "ஊ": return "ூ";
            case "எ": return "ெ";
            case "ஏ": return "ே";
            case "ஐ": return "ை";
            case "ஒ": return "ொ";
            case "ஓ": return "ோ";
            case "ஔ": return "ௌ";
            default: return null;
        }
    }
    remove(data) {
        if (data?.done) return;
        this.setState({ text: this.state.text.substring(0, this.state.text.length - 1) });
    }
    check(data) {
        if (data?.done) return;
        if (data?.array[0].length !== this.splitText(this.state.text).split('|').length) {
            this.setState({ showToast: true });
            setTimeout(() => {
                this.setState({ showToast: false });
            }, 3000);
        } else {
            var word = this.getData();
            var wordsplit = this.splitText(word.Word).split('|');
            var gameData = decryptWithAES(localStorage.getItem(gameName));
            var eRes = [];
            if (gameData !== null){
                if (gameData.store?.Day === word.Day){
                    eRes = gameData.store?.existingResult;
                } else {
                    gameData["store"] = { Day : word.Day, existingResult : eRes };
                }
            } else {
                gameData = { store: { Day : word.Day, existingResult : eRes }};
            }
            var checkWord = this.splitText(this.state.text).split('|');
            var updateArray = [];
            for (var i = 0; i < checkWord.length; i++) {
                updateArray.push({ letter: checkWord[i], guess: this.colorlogic(i, wordsplit, checkWord) });
            }
            eRes.push(updateArray);
            gameData.store.existingResult = eRes;
            this.setState({ text: "" });
            localStorage.setItem(gameName, encryptWithAES(gameData));
            var lastWordChecked = "";
            eRes[eRes.length - 1]?.forEach(x => lastWordChecked = lastWordChecked + x.letter);
            var showleaderboard= false;
            if (lastWordChecked === word.Word) {
                if (!gameData.guess) {
                    gameData.count = gameData.count + 1;
                    gameData.guess = { count1: 0, count2: 0, count3: 0, count4: 0, count5: 0, count6: 0 };
                    gameData.guess["count" + eRes.length] = gameData.guess["count" + eRes.length] + 1;
                    localStorage.setItem(gameName, encryptWithAES(gameData));
                } else {
                    gameData.count = gameData.count + 1;
                    gameData.guess["count" + eRes.length] = gameData.guess["count" + eRes.length] + 1;
                    localStorage.setItem(gameName, encryptWithAES(gameData));
                }
                showleaderboard = true;
            } else if (eRes.length  === 6){
                if (!gameData.guess) {
                    gameData.count = 0;
                    gameData.guess = { count1: 0, count2: 0, count3: 0, count4: 0, count5: 0, count6: 0 };
                }
                gameData.count = gameData.count + 1;
                localStorage.setItem(gameName, encryptWithAES(gameData));
            }
            if (eRes.length  === 6 || showleaderboard) {
                this.props.silentUpdateTamilee({ ["day" + word.Day]: encryptWithAES(gameData) });
            }
            this.AnimationCustom(eRes.length - 1, showleaderboard);
        }
    }
    AnimationCustom(row, showleaderboard){
        var col = 0;
        this.processrow = row;
        this.RotateAnimationByChangingCss(row, col);
        timer = setInterval(() => {
            col++;
            this.processcol = col;
            this.RotateAnimationByChangingCss(row, col);
            if (col === 4) {
                if (row === 5){
                    setTimeout(() => {
                        this.processcol = -1;
                        this.setState({ dummy: !this.state.dummy });
                    }, 1000);
                }
                if (showleaderboard){
                    setTimeout(() => {
                        this.setState({ completed: true });
                        this.props.showHeaderOptions('leaderboard');
                    }, 800);
                }
                clearInterval(timer);
            }
        }, 500);
    }
    RotateAnimationByChangingCss(row, col) {
        var opencard = document.getElementById("card" + row + col);
        if (!opencard.classList.contains('tamilee_on')) {
            opencard.classList.remove('tamilee_off');
            opencard.classList.add('tamilee_on');
        } else {
            opencard.classList.remove('tamilee_on');
            opencard.classList.add('tamilee_off');
        }
    }
    colorlogic(pos, word, userWord) {
        if (word[pos] === userWord[pos]) {
            return "correctplaceandword";
        } else if (word[pos].substring(0, 1) === userWord[pos].substring(0, 1)) {
            return "correctplaceandwordpartial";
        } else if (this.availableInTheWord(userWord[pos], word, false)) {
            return "correctwordnotplace";
        } else if (this.availableInTheWord(userWord[pos], word, true)) {
            return "correctwordpartialnotplace";
        } else {
            return "notcorrect";
        }
    }
    availableInTheWord(letter, word, partial) {
        var result = false;
        word.forEach(x => {
            if (x === letter && !partial) {
                result = true;
            }
            if (x.substring(0, 1) === letter.substring(0, 1) && partial) {
                result = true;
            }
        });
        return result;
    }
    getData() {
        if (this.props.game?.todayData) {
            return decryptWithAES(this.props.game.todayData);
        } else return null;
    }
    createGameData() {
        var gameData = decryptWithAES(localStorage.getItem(gameName));
        var eRes = [];
        var word = this.getData();
        if (gameData?.store){
            if (gameData.store?.Day === word.Day){
                eRes = gameData.store?.existingResult;
            } else {
                gameData["store"] = { Day : word.Day, existingResult : eRes };
            }
        } else {
            gameData = { store: { Day : word.Day, existingResult : eRes }};
        }
        var wordsplit = this.splitText(word.Word).split('|');
        var array = [];
        for (var i = 0; i < wordsplit.length; i++) {
            array.push({ letter: "", guess: "" });
        }
        var rows = [];
        eRes.forEach(x => rows.push(x));
        for (var j = 0; j < (6 - eRes.length); j++) {
            rows.push(array);
        }
        var done = false;
        if (eRes.length === 6) {
            done = true;
        }
        var lastWordChecked = "";
        eRes[eRes.length - 1]?.forEach(x => lastWordChecked = lastWordChecked + x.letter);
        if (lastWordChecked === word.Word) {
            done = true;
        }
        var letters = [];
        eRes.forEach(x => {
            x.forEach(l=>{
                if (l.letter.length === 1){
                    letters.push(l.letter);
                } else {
                    letters.push(l.letter.split('')[0]);
                    letters.push(l.letter.split('')[1]);
                }
            })
        });
        return {
            keyfound: letters,
            keyExist: word.Word.split(''),
            done: done,
            array: rows,
            day: word.Day,
            clue: [word.Clue1, word.Clue2]
        };
    }
    leaderboard() {
        var store = localStorage.getItem(gameName);
        var guess = [{ count: 0, persentage: '7%', name: 'count1' }, { count: 0, persentage: '7%', name: 'count2' }, { count: 0, persentage: '7%', name: 'count3' }, { count: 0, persentage: '7%', name: 'count4' }, { count: 0, persentage: '7%', name: 'count5' }, { count: 0, persentage: '7%', name: 'count6' }];
        var average = 0;
        if (store !== null && store !== undefined) {
            var result = decryptWithAES(store);
            if (result.guess) {
                var max = "count0";
                Object.keys(result.guess).forEach(key => {
                    if (result.guess[key] > result.guess[max]) {
                        max = key;
                    }
                });
                guess.forEach((item, i) => {
                    guess[i].count = result.guess[guess[i].name];
                    if (item.count === result.guess[max] && item.count !== 0) {
                        guess[i].persentage = '100%';
                    } else if (item.count === 0) {
                        guess[i].persentage = '7%';
                    } else {
                        guess[i].persentage = ((result.guess[guess[i].name] * 100) / result.guess[max]) + '%';
                    }
                });
                guess.forEach((item, i) => {
                    if (i !== 0) {
                        average = average + (i * item.count);
                    }
                });
            }
            result['guess'] = guess;
            return result;
        } else {
            return {
                count: 0,
                guess: guess
            };
        }
    }
    ShareText(data) {
        var newLine = `
`;
        var result = gameTitleInTamil + ' ' + data.day + newLine + newLine;
        data.array.forEach((x, i) => {
            x.forEach((y, j) => {
                result = result + this.colorCodeShare[y.guess === "" ? "empty" : y.guess];
            });
            result = result + newLine;
        });
        result = result + newLine + "https://bapagames.com/single/tamilsol";
        navigator.clipboard.writeText(result);
        this.setState({ showToastShare: true });
        setTimeout(() => {
            this.setState({ showToastShare: false });
        }, 3000);
    }
    showResult(data){
        if (data.done){
            var show = false;
            if (data.array[5][0].letter !== ""){
                data.array[5].forEach(x=>{
                    if (x.guess !== "correctplaceandword"){
                        show = true;
                    }
                })
            }
            return show;
        } else {
            return false;
        }
    }
    calculateKeyHeight(){
        var heights = {
            keyHeight: 0,
            keyboardHeight: 0,
            gridHeight: 0,
            gridItemHeight: "NaN"
        };
        if (this.state.innerWidth > 900 && this.state.innerHeight > 1200){
            heights.keyHeight = 48;
        } else {
            var h = this.state.innerHeight - 60;
            h = h * 0.2;
            h = h/4;
            h = h - 3;
            heights.keyHeight = Math.round(h);
        }
        heights.keyboardHeight = ((heights.keyHeight + 9) * 4) + 15;
        heights.gridHeight = this.state.innerHeight - (60 + heights.keyboardHeight + 5);
        if ((heights.gridHeight/7) < 73){
            heights.gridItemHeight = Math.round((heights.gridHeight/7) - 20);
        } else {
            if (this.state.innerWidth > 900 && this.state.innerHeight > 1200){
                heights.gridItemHeight = 65;
            } else {
                heights.gridItemHeight = 55;
            }
        }
        return heights;
    }
    showTheClue(id){
        var clue = JSON.parse(JSON.stringify(this.state.clue));
        clue[id] = true;
        this.setState({clue: clue});
    }
    getKeyColour(x, data){
        var clicked = data.keyfound.includes(x) || data.keyfound.includes(this.gethalfText(x));
        var exist = data.keyExist.includes(x) || data.keyExist.includes(this.gethalfText(x));
        if (clicked && !exist){
            return "key_notfound";
        } else if (clicked && exist){
            return "key_exist";
        } else {
            return "";
        }
    }
    render() {
        var heights = this.calculateKeyHeight();
        var rowToEnter = -1;
        var data = this.createGameData();
        var result = this.leaderboard();
        return (
            <React.Fragment>
                {
                    data &&
                    <div className="find_the_word_game_container" style={{ minHeight: `${this.state.innerHeight - 60}px`}}>
                        <div className='toast_message' style={{ display: this.state.showToast ? 'block' : 'none' }}>{"போதுமான எழுத்துக்கள் இல்லை"}</div>
                        <div className='toast_message' style={{ display: this.state.showToastShare ? 'block' : 'none' }}>{'கிளிப்போர்டுக்கு முடிவுகள் நகலெடுக்கப்பட்டன'}</div>
                        <div className="game_container">
                            <div className='page_container' style={{ height: `${heights.gridHeight}px` }}>
                                <div className='text_container'>
                                    {
                                        data?.array?.map((row, i) => {
                                            if (row[0].letter === "" && rowToEnter === -1) {
                                                rowToEnter = i;
                                            }
                                            var spText = this.splitText(this.state.text).split('|');
                                            return (
                                                <div key={i} className="text_row">
                                                    {
                                                        row.map((x, j) => {
                                                            return (
                                                                <div key={j} className="text_box_container" style={{ height: `${heights.gridItemHeight}px`}}>
                                                                    <div id={"card" + i + j} className={`tamilee_flipper ${(x.letter !== "" && this.processrow !== i) ? 'tamilee_off': 'tamilee_on'}`}>
                                                                        <div className='tamilee_front'> {/* flipper off */}
                                                                            <div style={{ background: this.colorCode[x.guess] }} className="text_box"><span className='tamil_text'>{rowToEnter === i ? spText[j] : x.letter}</span></div>
                                                                        </div>
                                                                        <div className='tamilee_back'> {/* flipper on */}
                                                                            <div className="text_box"><span className='tamil_text'>{rowToEnter === i ? spText[j] : x.letter}</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        (this.showResult(data) && this.processcol === -1) &&
                                        <div className="text_row">
                                            <div className='show_result_text'> 
                                                {
                                                    this.getData().Word
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='key_board_main' style={{ height: `${heights.keyboardHeight}px` }}>
                                <div className='key_board'>
                                    {
                                        [["க", "ங", "ச", "ஞ", "ட", "ண", "த", "ந", "ப"],
                                        ["ம", "ய", "ர", "ல", "வ", "ழ", "ள", "ற", "ன"],
                                        ["்", "அ", "ஆ", "இ", "ஈ", "உ", "ஊ", "எ"],
                                        ["check", "ஏ", "ஐ", "ஒ", "ஓ", "ஔ", "ஃ", "remove"]].map((row, rowi) => {
                                            return (
                                                <div key={rowi} className='key_board_row'>
                                                    <div className='key_board_row'>
                                                        {
                                                            row.map((x, i) => {
                                                                if (x !== "check" && x !== "remove") {
                                                                    return (
                                                                        <div key={i} className={classNames('key_board_key', this.getKeyColour(x, data))} style={{ height: `${heights.keyHeight}px` }} onClick={() => this.keyClick(x, rowi, data)}>{x}</div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div key={i} className='key_board_key' style={{ height: `${heights.keyHeight}px`, padding: '0 8px' }} onClick={() => { x === "check" ? this.check(data) : this.remove(data) }}>
                                                                            {x === "check" ? "சரிபார்" : <Icon>backspace</Icon>}
                                                                        </div>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Popup open={this.props.app.showHeaderOption === "leaderboard"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='message_over'>
                        <div className='result_rows'>
                            <h3>புள்ளிவிவரங்கள்</h3>
                        </div>
                        <div className='result_rows'>
                            <div className='result_flex'>
                                <div className='stat_column'>
                                    <div className='stat_count'>{result.count ? result.count : "0"}</div>
                                    <div className='stat_name'>விளையாடியவை</div>
                                </div>
                            </div>
                        </div>
                        <div className='result_rows'>
                            <h3>புள்ளியியல் பட்டியல்</h3>
                        </div>
                        <div className='result_rows_guess'>
                            {
                                result.guess.map((x, i) => {
                                    var lasgamecount = 0;
                                    if (data?.done) {
                                        data.array.forEach((x, row) => {
                                            if (x[0].letter !== "") {
                                                lasgamecount++;
                                            }
                                            if (x[0].letter !== "" && row === 5){
                                                x.forEach(l=>{
                                                    if (l.guess !== "correctplaceandword"){
                                                        lasgamecount = 0;
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    return (
                                        <div key={i} className='guess_count'>
                                            <div className='guess_count_number'>{x.name.substring(5)}</div>
                                            <div className='guess_progress_container'>
                                                <div className='guess_progress' style={{ width: x.persentage, backgroundColor: (i + 1) === lasgamecount ? "#ff7126" : "" }}>
                                                    <div className='player_guess'>{x.count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            data?.done &&
                            <div className='result_rows'>
                                <div className='ng_action_buttons'>
                                    <div className='ng_action_timer'>
                                        <span>{"அடுத்த சொல்:"}</span><br />
                                        <div id="timer" style={{ fontSize: '24px' }}>
                                            <Timer></Timer>
                                        </div>
                                    </div>
                                    <div className='ng_action_button' onClick={() => this.ShareText(data)}>
                                        {"பகிர்"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
                <Popup open={this.props.app.showHeaderOption === "idea"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='message_over'>
                        <div className='result_rows'>
                            <h2>குறிப்பு 1</h2>
                        </div>
                        <div className='result_rows' style={{textAlign: 'center'}}>
                            {
                                data?.array[1][0].letter !== "" ?
                                <React.Fragment>
                                    {
                                        this.state.clue[0]?
                                        <div className='show_actual_clue'>{data?.clue[0]}</div>:
                                        <div className='show_clue' onClick={()=>{this.showTheClue(0)}}>குறிப்பைக் காட்டு</div>
                                    }
                                </React.Fragment>: 
                                <h4>இரண்டாவது யூகத்திற்குப் பிறகு முதல் குறிப்பு கிடைக்கும்</h4>
                            }
                        </div>
                        <div className='result_rows'>
                            <h2>குறிப்பு 2</h2>
                        </div>
                        <div className='result_rows' style={{textAlign: 'center'}}>
                            {
                                data?.array[4][0].letter !== "" ?
                                <React.Fragment>
                                    {
                                        this.state.clue[1]?
                                        <div className='show_actual_clue'>{data?.clue[1]}</div>:
                                        <div className='show_clue' onClick={()=>{this.showTheClue(1)}}>குறிப்பைக் காட்டு</div>
                                    }
                                </React.Fragment>: 
                                <h4>ஐந்தாவது யூகத்திற்குப் பிறகு இரண்டாவது குறிப்பு கிடைக்கும்</h4>
                            }
                        </div>
                    </div>
                </Popup>
                <Popup open={this.props.app.showHeaderOption === "instruction"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='howtoplay'>
                        <div className='howtoplay_title'>
                            <h3>எப்படி விளையாடுவது?</h3>
                        </div>
                        <div className='howtoplay_intro'>
                            <p>ஆறு முயற்சிகளில் <span className='howtoplay_bold'>{gameTitleInTamil}</span> ஐ யூகிக்கவும். ஒவ்வொரு நாளும் ஒரு புதிய <span className='howtoplay_bold'>{gameTitleInTamil}</span> கிடைக்கும்!</p>
                            <p>ஒவ்வொரு யூகத்திற்கும் ஐந்தெழுத்து இருக்க வேண்டும். சமர்ப்பிக்க சரிபார் பொத்தானை அழுத்தவும்.</p>
                            <p>ஒவ்வொரு யூகத்திற்கு பிறகும், உங்கள் யூகம் வார்த்தைக்கு எவ்வளவு நெருக்கமாக இருந்தது என்பதைக் கட்டத்தின் நிறம் காட்டும்.</p>
                        </div>
                        <div className='howtoplay_intro'>
                            <h3>எடுத்துக்காட்டுகள்:</h3>
                        </div>
                        <div className='howtoplay_intro'>
                            <div className='text_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>ஆ</div>
                                <div className="game_text_cell" style={{ background: this.colorCode["correctplaceandword"] }}>சி</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>ரி</div>
                                <div className="game_text_cell" style={{ background: this.colorCode["correctplaceandwordpartial"] }}>ய</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>ர்</div>
                            </div>
                            <p><span className='howtoplay_bold'>சி</span> என்ற எழுத்து வார்த்தையில் சரியான இடத்தில் உள்ளது.</p>
                            <p><span className='howtoplay_bold'>ய</span> என்ற எழுத்து வார்த்தையில் சரியான இடத்தில் உள்ளது ஆனால் அகர வரிசை சரியாக இல்லை.</p>
                        </div>
                        <div className='howtoplay_intro'>
                            <div className='text_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>பு</div>
                                <div className="game_text_cell" style={{ background: this.colorCode["correctwordnotplace"] }}>த்</div>
                                <div className="game_text_cell" style={{ background: this.colorCode["correctwordpartialnotplace"] }}>த</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>க</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>ம்</div>
                            </div>
                            <p><span className='howtoplay_bold'>த்</span> என்ற எழுத்து வார்த்தையில் உள்ளது ஆனால் தவறான இடத்தில் உள்ளது.</p>
                            <p><span className='howtoplay_bold'>த</span> என்ற எழுத்து வார்த்தையில் உள்ளது ஆனால் தவறான இடத்தில் உள்ளது மற்றும் அகர வரிசையில் இல்லை.</p>
                        </div>
                        <div className='howtoplay_intro'>
                            <div className='text_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize) }}>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>வி</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>க</div>
                                <div className="game_text_cell" style={{ background: this.colorCode["notcorrect"] }}>ட</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>க</div>
                                <div className="game_text_cell" style={{ background: this.colorCode[""] }}>வி</div>
                            </div>
                            <p><span className='howtoplay_bold'>ட</span> என்ற எழுத்து எந்த இடத்திலும் வார்த்தையில் இல்லை மற்றும் அதன் அகர வரிசையும்.</p>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }
}

export const FindTheWord = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_FindTheWord)
);