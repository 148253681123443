import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Select.css'

function mapStateToProps({ field }) {
  return {
    validation: field
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
};

class _Select extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    class: PropTypes.string,
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
    hasWarning: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    disabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    regExPattern: PropTypes.string,
    style: PropTypes.object,
    validation: PropTypes.object
  };

  render() {
    const className = classNames(
      "sel_container", {
      "Disabled": this.props.disabled,
      "sel_confirmation": this.props.isConfirmed,
      "sel_warning": this.props.hasWarning,
      "sel_error": (this.props.validation && this.props.validation[this.props.name])
    },
      this.props.className
    );
    const placeholder = (this.props.placeholder ? this.props.placeholder : "") + (this.props.optional ? ' (Optional)' : '');
    const disabled = this.props.disabled && true ? 'disabled' : false;

    return (
      <React.Fragment>
        <div className={className} style={this.props.style}>
          {this.props.title && <p className={classNames("sel_information_text")}>{this.props.title}</p>}
            <select
            className={classNames("sel_input", {"sel_error" : (this.props.validation && this.props.validation[this.props.name])})}
            name={this.props.name}
            type={this.props.type}
            onBlur={event => {
              if (this.props.onBlur) this.props.onBlur(event);
            }}
            onChange={event => {
              if (this.props.onChange) this.props.onChange(event);
            }}
            value={this.props.value || (this.props.options && this.props.options[0].text)}
            placeholder={placeholder ? placeholder : ""}
            disabled={disabled}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                if (this.props.onSubmit) this.props.onSubmit(e);
              }
            }}
            >
              {
                this.props.options?.map((x,i)=>{
                  return(
                    <option key={i} value={x.value}>{x.text}</option>
                  )
                })
              }
            </select>
        </div>
      </React.Fragment>
    );
  }
}

export const Select = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(_Select)
);