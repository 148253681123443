import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ArrangeImage.css';
import { GetArrangeLeaderBoard, OpenPopup, SelectPlayer, ShowHeaderOptions, UpdateArrangeLeaderBoard } from '../../Actions';
import image from '../../Images/6057684e5923ad2ae43c8150_bavassano_homepage_before.jpeg';
import { Popup } from '../../Components';

var timer = null;
var gameTitle = 'TAMILEE';
var gameName = 'BAPA_GAMES_' + gameTitle;

var random = {
    grid3: [ 
        [3, 5, 4, 0, 6, 1, 2, 7, 8], [2, 5, 7, 6, 4, 0, 1, 3, 8], [5, 1, 6, 4, 7, 3, 0, 2, 8], [2, 4, 1, 7, 5, 6, 0, 3, 8],
        [4, 7, 1, 2, 6, 3, 0, 5, 8], [7, 0, 2, 1, 6, 4, 3, 5, 8], [6, 0, 7, 5, 4, 2, 3, 1, 8], [6, 5, 0, 4, 2, 7, 3, 1, 8]
    ],
}

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectPlayer: p =>{
            dispatch(SelectPlayer(p));
        },
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
        showHeaderOptions: option => {
            dispatch(ShowHeaderOptions(option));
        },
        getLeaderboard: data => {
            dispatch(GetArrangeLeaderBoard(data));
        },
        updateLeaderboard: (progress, data) => {
            dispatch(UpdateArrangeLeaderBoard(progress, data));
        }
    };
};

class _ArrangeImage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props); 
        this.gridSize = 3;
        this.timerCount = 0;
        this.moveCount = 0;
        this.userName = "";
        this.myArr = this.createMarginArray(this.gridSize*this.gridSize, this.gridSize);
        this.state = {
            userName: this.getUserName(),
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            size: 1000,
            position: this.createArrayOfSize(this.gridSize*this.gridSize),
        }
    }
    componentDidMount(){
        this.InitiateGame();
        this.windowResize();
        window.addEventListener('load', ()=>this.windowResize());
        window.addEventListener('resize', ()=>this.windowResize());
        this.props.getLeaderboard(false);
    }
    componentWillUnmount(){
        clearInterval(timer);
    }
    windowResize(){
        this.setState({ innerWidth : window.innerWidth, innerHeight: window.innerHeight});
    }
    InitiateGame(){
        var randomGrid = random["grid" + this.gridSize];
        var r = this.generateRandomNumber(0,randomGrid.length);
        var position = randomGrid[r];
        this.setState({ position: position, empty: 8, won: false });
        this.timerCount = 0;
        this.moveCount = 0;
        timer = setInterval(() => {
            this.timerCount++;
        }, 1000);
    }
    createArrayOfSize(size){
        var array = [];
        for (var i = 0; i < size; i++){
            array.push(i);
        }
        return array;
    }
    createMarginArray(size, gsize){
        var array = [];
        for (var i = 0; i < size; i++){
            array.push([`-${parseInt(i/gsize,10) * 100}%`, `-${i%gsize * 100}%`]);
        }
        return array;
    }
    generateRandomNumber = (min, max) => {
        var random = Math.random() * (max - min) + min;
        return parseInt(random, 10);
    }
    goHome(){
        if (this.props.user.loggedIn){
            this.props.history.push(this.props.app.loginHome);
        } else {
            this.props.history.push(this.props.app.home);
        }
    }
    moveAndChangeState(x){
        this.moveImage(this.state.empty, x, this.state.position);
    }
    moveImage(e, x, p){
        if (e - x === this.gridSize) { //top => bottom
            this.actualMove(e, x, p);
        } else if (e - x === 1 && (x+1)%this.gridSize !== 0) { //left => right
            this.actualMove(e, x, p);
        } else if (e - x === -this.gridSize) { //bottom => top
            this.actualMove(e, x, p);
        } else if (e - x === -1 && x%this.gridSize !== 0) { //right => left
            this.actualMove(e, x, p);
        }
    }
    actualMove(e, x, position){
        var c = position[x];
        position[x] = position[e];
        position[e] = c;
        this.moveCount++;
        var won = JSON.stringify(position) === JSON.stringify(this.createArrayOfSize(this.gridSize*this.gridSize));
        if (won){
            clearInterval(timer);
        }
        this.setState({ position: position, empty: x, won: won });
    }
    PlayAgain(){
        this.InitiateGame();
    }
    RowFr(s){
        var fr = "";
        for (var i = 0; i < s; i++){
            fr = fr + '1fr ';
        }
        return fr;
    }
    getGridSize(){
        if (this.state.innerWidth > this.state.innerHeight){
            return this.state.innerHeight - (60 + 100);
        } else {
            return this.state.innerWidth > 1000 ? 900 : Math.round(this.state.innerWidth * 0.75);
        }
    }
    pushToLeaderBoard(){
        var user = this.state.userName;
        if (user !== "" && user.length >= 3){
            this.storeUserName(user);
            this.props.updateLeaderboard(false, { moves: this.moveCount, time: this.timerCount, user: user });
            this.props.showHeaderOptions('leaderboard');
        } else {
            alert("User name must be minimum 3 letters.");
        }
    }
    storeUserName(e){
        return localStorage.setItem(gameName+ "@username", e);
    }
    getUserName(){
        return localStorage.getItem(gameName+ "@username");
    }
    skip(){
        this.setState({won: false});
        this.props.showHeaderOptions('leaderboard');
    }
    render() {
        return (
            <div className="game1_main_container" style={{ minHeight: `${this.state.innerHeight - 60}px`}}>
                <div className="game1_main" style={{ width: `${this.getGridSize()}px`, height: `${this.getGridSize()}px`, gridTemplateRows: this.RowFr(this.gridSize)}}>
                    {
                        this.createArrayOfSize(this.gridSize).map((x,i)=>{
                            return(
                                <div key={i} className='game1_image_section_row' style={{ gridTemplateColumns: this.RowFr(this.gridSize)}}>
                                    {
                                        this.createArrayOfSize(this.gridSize).map((y,j)=>{
                                            var z = ((x+1)*this.gridSize)-this.gridSize+y;
                                            return(
                                                <div key={j} className='game1_image_section' onClick={()=>this.moveAndChangeState(z)}>
                                                    {/* <div style={{position:'absolute'}}>{this.state.position[z]+1}</div> */}
                                                    {(this.state.position[z] !== (this.gridSize * this.gridSize - 1) || this.state.won) ? <img src={image} className="game1_image" alt="" draggable={false} style={{ width: `${this.gridSize * 100}%`, height: `${this.gridSize * 100}%`, marginTop: this.myArr[this.state.position[z]][0], marginLeft: this.myArr[this.state.position[z]][1] }}></img> : null}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <Popup open={this.props.app.showHeaderOption === "leaderboard"} closePopup={() => { this.props.showHeaderOptions('') }} outsideClickClose>
                    <div className='message_over'>
                        <div className='result_rows'>
                            <h3>LEADERBOARD</h3>
                        </div>
                        <div className='leader_board'>
                            <div className='leader_board_row'>
                                <div className='leader_name bold'>Name</div>
                                <div className='leader_move bold'>Moves</div>
                                <div className='leader_time bold'>Time<br/>(sec)</div>
                            </div>
                            {
                                this.createArrayOfSize(10).map((x,i)=>{
                                    return(
                                        <div className='leader_board_row'>
                                            <div className='leader_name'>asdfasf</div>
                                            <div className='leader_move'>112</div>
                                            <div className='leader_time'>12</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Popup>
                <Popup open={(this.state.won && this.props.app.showHeaderOption !== "leaderboard")} closePopup={()=>this.skip()} outsideClickClose={false}>
                    <div className='message_won'>
                        <div className='message_won_text' style={{ fontSize: '1.2em'}}>
                            You have taken {this.timerCount} seconds and {this.moveCount} moves in to complete the puzzule.
                        </div>
                        <div className='message_won_text'>
                            <input type={'text'} style={{ width: `${this.getGridSize()/2}px`, height: '50px', textAlign: 'center'}} placeholder="Enter your name." onChange={(e)=>{ this.setState( {userName : e.target.value}) }} value={this.state.userName}/>
                        </div>
                        <div className='home_select_button' onClick={()=>this.pushToLeaderBoard()}>
                            Push to Leaderboard
                        </div>
                        <div className='home_select_button' onClick={()=>this.skip()}>
                            Skip
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}

export const ArrangeImage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_ArrangeImage)
);