
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export const generateRandomNumber = (min, max) => {
    var random = Math.random() * (max - min) + min;
    return parseInt(random, 10);
}
export const getSecret = () => {
    var i = ["a", "m", "p", "-"];
    var t = "";
    [0, 1, 1, 0, 3, 0, 2, 2, 0].forEach(x => t = t + i[x]);
    return t;
}

export const decryptWithAES = (ciphertext) => {
    if (ciphertext == null) return null;
    const bytes = AES.decrypt(ciphertext, getSecret());
    const originalText = bytes.toString(Utf8);
    return originalText ? JSON.parse(originalText) : null;
};

export const encryptWithAES = (text) => {
    return AES.encrypt(JSON.stringify(text), getSecret()).toString();
};
